.background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
.wrapper {
  position: fixed;
  bottom: 50px;
  right: 30px;
  width: 65px;
  height: 65px;
  background-color: var(--primary-color);
  border: 5px solid #ffcbcd;
  border-radius: 50%;
  z-index: 100;
  display: grid;
  place-items: center;
}
.close {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(80%, -80%);
  cursor: pointer;
}
.icon {
  width: 50%;
  height: 50%;
}
.content_wrap {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-105%, -50%);
  display: grid;
  place-items: center;
  min-width: 261px;
  min-height: 76px;
}
.content {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  font-size: 13px;
  padding: 12px 0;
  margin-right: 15px;
  height: 100%;
  p {
    font-size: 13px;
    font-weight: 300;
  }
  // left: 0;
  // top: 0;
}
