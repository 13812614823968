.pid {
  margin-top: 20px !important;
}
.titleBold {
  font-size: 40px;
}
.top {
  margin-top: 40px;
  position: relative;
  display: flex;
}
.col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 2;
}
.carousel {
  width: 100%;
  flex: 3;
}
.carousel > div {
  overflow: inherit !important;
}
.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;

  background: #ffffff;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  width: 52px;
  height: 56px;

  border: none;

  display: flex;
  justify-content: center;
  align-items: center;
}
.primaryText {
  color: #e5474c;
}
.secondaryBtn {
  padding: 22px 65px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  text-align: center;
  color: #000;
  background: transparent;
  border-radius: 100px;
  border: 2px solid #e6474c;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  width: 100%;
}
.secondaryBtn:hover {
  background-color: #e5474c;
  color: white;
}
.bottomBtn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
}
.topInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.imgCarusel {
  max-width: 350px;
  max-height: 350px;
}
.contacts {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 20px;
  align-items: center;
}
.contact_buttons {
  display: flex;
  gap: 10px;
}
.socialItem {
  width: 32px;
  height: 32px;
  padding: 5px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  display: flex;
  justify-content: center;
}
.socialItem:hover {
  background-color: #e5474c;
}
.socialItem > img {
  width: 80%;
  height: 80%;
}

/* !Body */
.description {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 50px;
}
.description > p {
  font-size: 20px;
  line-height: 40px;
}
.description > ul {
  list-style: none;
  position: relative;
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 20px;
}
.description > ul > li:before {
  content: ""; /* placeholder for the SVG */
  position: absolute;
  left: 0; /* place the SVG at the start of the padding */
  width: 1em;
  height: 1em;
  background: url("data:image/svg+xml,%3Csvg width='15' height='13' viewBox='0 0 15 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.39662 9.43382L1.55981 6.59704L0 8.15686L3.74354 11.9004L3.74784 11.896L4.43125 12.5795L15 2.01065L13.4099 0.420532L4.39662 9.43382Z' fill='%23D7392E'/%3E%3C/svg%3E%0A")
    no-repeat;
}
.titles {
  display: flex;
  gap: 60px;
}
.titles h1 {
  cursor: pointer;
}
.title {
  font-size: 30px;
  font-weight: 400;
  color: #e5474c;
}
.title_unselected {
  font-weight: 400;
  font-size: 26px;
  color: #d0d0d0 !important;
  border-bottom: 1px dashed #d0d0d0;
  line-height: 45px;
}
.unfocus {
  color: #d0d0d0 !important;
  padding-left: 40px;
  margin-top: 20px;
}
.infoName {
  min-width: 80px;
}
/* !Locations */
.locations {
  margin-bottom: 50px;
}
.info_location {
  margin: 30px 0px;
  display: flex;
  gap: 100px;
}
.info_location > p {
  font-size: 20px;
}

/* ! Reviews */
.reviews_block {
  background: rgba(217, 217, 217, 0.5);
  opacity: 0.5;
  border-radius: 9px;
  padding: 20px;
}
.avatar {
  border-radius: 50%;
}

.rec li > a {
  margin: 0 20px !important;
}
.rec img {
  height: 100px;
  width: auto !important;
}
.image {
  width: 100%;
  flex: 3;
  max-height: 400px;
  display: flex;
}
.image img {
  object-fit: contain;
  max-height: 400px;
}


 .partnerWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 875px) {
  .partnerWrap{
    display: block;
  }
  .image img {
    width: 100%;
  }
}

/* .partnerWrap div {
  flex: 1;
}
.partnerWrap .image {
}

.partnerWrap .image img{
  max-width: 100%;
}
.topInfo{
  max-width: 400px;
} */

