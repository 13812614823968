.wrapper1 {
  display: flex;
  position: "relative";
  gap: 20px;
  width: 100%;
}
.svgWrapper {
  position: relative;
  width: 100%;
  min-height: 247px;
}
.svg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.discount_procent {
  border-radius: 10px;
  padding: 5px;
  font-size: 12px;
  color: white;
  background-color: rgba(0, 0, 0, 0.2);
  transform: translate(-50%, -140%);
  position: absolute;
}
.shadow {
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.3));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.3));
}
.bgSvg {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  display: flex;
}
.effect {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 43px;
  height: 40px;
  background: linear-gradient(48deg, #ebebeb 30%, transparent 100%);
  z-index: 1;
}
.bgBlock {
  width: 44px;
  height: 100%;
  background-color: #ebebeb;
  background-image: linear-gradient(to bottom, white 1px, transparent 1px);

  &.odd {
    background-color: #f4f4f4;
  }
  &.marked {
    background-color: #ffd7d8;
  }
}
.months {
  position: absolute;
  bottom: -10px;
  transform: translateY(100%);
  display: flex;
  justify-content: space-around;
  padding-left: 15px;
  width: 100%;
}
.discounts {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 6px 0;
  span.current {
    color: var(--primary-color);
    font-weight: bold;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translate(5px, -50%);
      border-left: 7px solid #bfbfbf;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
    }
  }
}
