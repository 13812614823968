.labels {
  display: flex;
  margin: 40px 0 40px 0;
  & > * {
    width: 50%;
    padding-left: 20px;
    color: #949494;
  }
}
.list {
  display: grid;
  gap: 2em;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: row;
}
.block {
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  border-radius: var(--radius);
  padding: 30px;
  display: flex;
  gap: 1em;
  p {
    overflow-x: hidden;
    word-wrap: break-word;
    hyphens: auto;
  }
  & > :first-child {
    width: 69%;
  }
  & > :last-child {
    width: 31%;
  }
}

@media screen and (max-width: 850px) {
  .labels {
    display: none;
  }
  .list {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
  }
}
