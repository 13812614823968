.title {
  font-size: 40px;
  margin-bottom: 20px;
  position: relative;
  z-index: 100;
}
.proms {
  margin-top: 50px;
  position: relative;
}
.first_bg{
  background: linear-gradient(90deg, rgba(255,255,255,1) 95%, rgba(255,255,255,0) 100%);
  position: absolute;
  left: 0;
  top: 0;
  width: 110%;
  transform: translate(-94%, 0);
  height: 110%;
  z-index: 10;
}
.second_bg{
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 5%);
  position: absolute;
  right: 0;
  top: 0;
  width: 110%;
  transform: translate(92%, 0);
  height: 110%;
  z-index: 5;
}
.row {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  justify-content: left;
}
.wrap_prom {
  background-color: transparent;
  padding: 0 20px;
}
.prom {
  cursor: pointer;
  border-radius: 21px;
  border: 1px solid #ececf3;
  background-color: white;
  /* width: 550px; */
  height: 241px;
  overflow: hidden;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  display: flex;
}
.prom > img {
  height: 100%;
}
.prom_bottom {
  padding: 20px;
}
.prom_bottom > h3 {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 10px;
}
.desc {
  font-weight: 400;
  font-size: 16px;
  color: rgba(43, 44, 50, 0.4);

  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.arrow {
  background: #ffffff;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  position: absolute;
  padding: 10px 20px;
  color: #00000020;
  font-size: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.disabled {
  pointer-events: none;
  background-color: #ececec;
}
@media screen and (max-width: 850px) {
  .proms{
    margin-top: 10px;
  }
  .title {
    font-size: 24px;
  }
}
