.recommended {
  &::after {
    content: "";
    position: absolute;
    z-index: -6;
    right: -9px;
    z-index: -1;
    top: 64px;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-right: 12px solid #a31519;
    border-bottom: 0px solid transparent;
    transform: rotate(180deg);
    border-radius: 8px;
  }
}

.text {
  color: white;
  font-weight: 500;
  position: absolute;
  top: 30px;
  right: -8px;
  background-color: var(--primary-color);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 9px 16px 9px 22px;
  z-index: 3;
  border-radius: 2px 2px 2px 18px;
}

.wordWrap {
  word-wrap: break-word;
}

.tariff_left_block_wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  width: 100%;

  @media screen and (max-width: 850px) {
    gap: 30px 0;
  }
}

.tariff_divider {
  position: relative;
  border-top: 1px solid #aaa;

  &::before {
    content: "+";
    position: absolute;
    top: -20px;
    padding: 5px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    left: 50%;
    transform: translate(-50%);
    box-shadow: 0 0 5px 2px #00000021;
    border-radius: 10px;
    font-size: 25px;
    font-weight: 600;
  }
}

.tatiff_block_top,
.tatiff_block_bottom {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;

  & > img {
    width: 10rem;

    @media screen and (max-width: 850px) {
      width: 8rem;
    }
  }
}

.tatiff_block_top {
  .tatiff_block_info {
    display: flex;
    flex-direction: column-reverse;
    width: 155px;
  }
}

.tatiff_block_bottom {
  .tatiff_block_info {
    display: flex;
    flex-direction: column;
    width: 155px;
  }
}

.tatiff_block_info {
  h2 {
    font-size: 25px;
  }

  span {
    font-size: 18px;
  }
}
