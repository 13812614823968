.Share__pic {
  margin-bottom: 50px;
}

.Share__pic img {
  border-radius: 10px;
  box-shadow: 4px 5px 20px rgba(0, 0, 0, 0.3);
  width: 100%;
}

.Share__socialsTitle {
  margin: 0 0 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 30px;
  color: #000000;
}

.Share__socials {
  display: flex;
  width: 100%;
}

.Share__social {
  background-color: #d5d5d5;
  min-height: 40px;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  border-radius: 50%;
  transition: 0.3s background ease-in-out;
}

.Share__social:hover {
  transition: 0.3s background ease-in-out;
  background-color: #e5474c;
}

.Share__social img {
  height: 40px;
  padding: 9px;
}

@media (max-width: 991px) {
  .Share {
    text-align: center;
  }

  .Share__socialsWr {
    position: absolute;
    bottom: -70px;
    width: 100%;
  }

  .Share__socials {
    justify-content: center;
  }
}
