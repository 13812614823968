.react-tabs__tab--selected p {
  color: #e84247;
  transition: 0.2s all;
}

.partners-tabs {
  margin-bottom: 40px;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &__list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
    &-tab {
      width: 129px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 0;
      justify-content: space-between;
      border-radius: 20px;
      transition: 0.2s all;
      p {
        justify-content: center;
        text-align: center;
        display: flex;
        align-items: center;
        font-size: 18px;
        transition: 0.2s all;
        height: 92px;
      }
      &:hover {
        transform: translateY(-10%);
        transition: 0.3s transform;
      }
      &-wrapperImage {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #e5474c;
        fill: #e5474c;
        border-radius: 100px;
        width: 110px;
        height: 110px;
      }
      &-wrapperImageActive {
        background-color: #e5474c;
      }
    }
    .react-tabs__tab--selected p {
      color: #e84247;
      transition: 0.2s all;
    }
    &-tab.all {
      div {
        height: 80px;
        width: 100%;
        background: url("../../assets/images/partners/categories.svg");
        background-size: 80px;
        background-position-x: center;
        background-repeat: no-repeat;
      }
    }
    &-tab.beauty {
      div {
        height: 80px;
        width: 100%;
        background: url("../../assets/images/partners/beauty.svg");
        background-size: 80px;
        background-position-x: center;
        background-repeat: no-repeat;
      }
    }
    &-tab.joy {
      div {
        height: 80px;
        width: 100%;
        background: url("../../assets/images/partners/joy.svg");
        background-size: 80px;
        background-position-x: center;
        background-repeat: no-repeat;
      }
    }
    &-tab.other {
      div {
        height: 80px;
        width: 100%;
        background: url("../../assets/images/partners/other-services.svg");
        background-size: 80px;
        background-position-x: center;
        background-repeat: no-repeat;
      }
    }
    &-tab.education {
      div {
        height: 80px;
        width: 100%;
        background: url("../../assets/images/partners/education.svg");
        background-size: 80px;
        background-position-x: center;
        background-repeat: no-repeat;
      }
    }
    &-tab.style {
      div {
        height: 80px;
        width: 100%;
        background: url("../../assets/images/partners/style.svg");
        background-size: 80px;
        background-position-x: center;
        background-repeat: no-repeat;
      }
    }
    &-tab.food {
      div {
        height: 80px;
        width: 100%;
        background: url("../../assets/images/partners/food.svg");
        background-size: 80px;
        background-position-x: center;
        background-repeat: no-repeat;
      }
    }
    .react-tabs__tab--selected.all {
      div {
        height: 80px;
        width: 100%;
        background: url("../../assets/images/partners/categories-active.svg");
        background-size: 80px;
        background-position-x: center;
        background-repeat: no-repeat;
      }
    }
    .react-tabs__tab--selected.beauty {
      div {
        height: 80px;
        width: 100%;
        background: url("../../assets/images/partners/beauty-active.svg");
        background-size: 80px;
        background-position-x: center;
        background-repeat: no-repeat;
      }
    }
    .react-tabs__tab--selected.education {
      div {
        height: 80px;
        width: 100%;
        background: url("../../assets/images/partners/education-active.svg");
        background-size: 80px;
        background-position-x: center;
        background-repeat: no-repeat;
      }
    }
    .react-tabs__tab--selected.style {
      div {
        height: 80px;
        width: 100%;
        background: url("../../assets/images/partners/style-active.svg");
        background-size: 80px;
        background-position-x: center;
        background-repeat: no-repeat;
      }
    }
    .react-tabs__tab--selected.food {
      div {
        height: 80px;
        width: 100%;
        background: url("../../assets/images/partners/food-active.svg");
        background-size: 80px;
        background-position-x: center;
        background-repeat: no-repeat;
      }
    }
    .react-tabs__tab--selected.joy {
      div {
        height: 80px;
        width: 100%;
        background: url("../../assets/images/partners/joy-active.svg");
        background-size: 80px;
        background-position-x: center;
        background-repeat: no-repeat;
      }
    }
    .react-tabs__tab--selected.other {
      div {
        height: 80px;
        width: 100%;
        background: url("../../assets/images/partners/other-services-active.svg");
        background-size: 80px;
        background-position-x: center;
        background-repeat: no-repeat;
      }
    }
  }
  &__tab-panel {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    &-container {
      display: flex;
      flex-direction: row;
    }
  }
}

.partners-tabs__tab-panel--container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 60px;
}

@media (max-width: 1200px) {
  .partners-tabs {
    &__list {
      flex-wrap: wrap;
      &-tab {
        flex: 0 0 32%;
        max-width: 32%;
        margin-bottom: 1.5%;
        &:first-child {
          order: 1;
          flex: 0 0 100%;
          max-width: 100%;
          p {
            height: 50px;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .partners-tabs__tab-panel--container {
    width: 300px;
  }
  .partners-tabs__list-tab-wrapperImage{
    border-radius: 0;
  }
  .partners-tabs {
    &__list {
      &-tab {
        flex: 0 0 100%;
        max-width: 100%;

        &:first-child {
          margin: 0 auto;
          order: -1;
          p {
            height: 92px;
          }
        }
      }
    }

    &__tab-panel {
      flex-wrap: wrap;
    }
  }
}
