.AdditionalServices {
  text-align: left;
  flex: 1;
  margin-bottom: 100px;
}

.AdditionalServices__inner {
  max-width: 1200px;
  margin: 0 auto;
}

.AdditionalServices__headTitle {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 30px;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 40px;
  color: #000000;
  text-align: center;
}

.AdditionalServices__infoTop {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 467px;
  width: 100%;
  height: 93px;
  padding: 0 0 25px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #898b96;
  background: url('../assets//images/info.png') 0 0 no-repeat;
  background-size: cover;
}

.AdditionalServices__infoTop img {
  margin-right: 20px;
  max-width: 100%;
}

.AdditionalServices__items {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
}

.AdditionalServices__col {
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 0 15px 15px;
}

.AdditionalServices__item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 35px 30px 15px;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}

.AdditionalServices__top {
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  min-height: 260px;
}

.AdditionalServices__top a[target='_blank']:hover {
  color: #da141b;
}

.AdditionalServices__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  height: 78px;
}

.AdditionalServices__icon img {
  max-width: 100%;
  height: 78px;
}

.AdditionalServices__title {
  margin-bottom: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.408px;
  color: rgba(0, 0, 0, 0.8);
}

.AdditionalServices__col:first-child .AdditionalServices__title {
  white-space: nowrap;
}
.AdditionalServices__text {
  margin-bottom: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: rgba(0, 0, 0, 0.5);
}

.AdditionalServices__link {
  flex: 1;
  display: flex;
  align-items: flex-end;
  align-self: flex-start;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: rgba(0, 0, 0, 0.5);
  border-bottom: 1px dashed #cfcfcf;
  transition: all 0.3s ease-in;
}

/* .AdditionalServices__link:hover {
  color: #da141b;
  border-bottom-style: dotted;
  border-bottom-color: #da141b;
} */

.AdditionalServices__info {
  flex: 1;
  display: flex;
  align-items: flex-end;
  align-self: flex-start;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #000000;
}

.AdditionalServices__bot {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
}

.AdditionalServices__free {
  flex: 0 0 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
  border-right: 1px solid #ebebeb;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: -0.408px;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
  transition: all 0.3s ease-in;
}

.AdditionalServices__more {
  flex: 0 0 50%;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #da141b;
  transition: all 0.3s ease-in;
  cursor: pointer;
}

.AdditionalServices__more:hover {
  text-decoration: underline;
}

@media (max-width: 1210px) {
  /* .AdditionalServices__inner {
    padding: 0 20px;
  } */
}

@media (max-width: 991px) {
  .AdditionalServices__headTitle {
    font-size: 28px;
    line-height: 30px;
  }

  .AdditionalServices__col {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}

@media (max-width: 768px) {
  .AdditionalServices__col {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .AdditionalServices__headTitle {
    flex-direction: column;
    align-items: center;
  }

  .AdditionalServices__infoTop {
    max-width: 100%;
    margin-top: 20px;
    height: auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 20px;
    margin-bottom: 20px;
  }
}

@media (max-width: 500px) {
  .AdditionalServices__col {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .AdditionalServices {
    margin-bottom: 50px;
  }
}
