.steps-container {
  display: flex;
  flex-direction: column;
}
.step-buttons {
  display: flex;
  gap: 20px;
}
.step-content {
  display: flex;
}
.step-number-content {
  display: flex;
  align-items: center;
  height: fit-content;
  width: max-content;
}
.step-checkmark {
  margin-right: 20px;
  transition: all 0.1s ease-out;
  width: 22px;
  height: 22px;
  background-color: #e5ffd9;
  border-radius: 50%;
  position: relative;
  &.inactive {
    background-color: #ececec;
    &::after {
      left: 5px;
      width: 12px;
      top: calc(50% - 6px);
      border-radius: 50%;
      height: 12px;
      background-color: white;
      border: none;
      transform: none;
    }
  }
  &.current {
    background-color: var(--primary-color);
    &::after {
      left: 5px;
      width: 12px;
      top: calc(50% - 6px);
      border-radius: 50%;
      height: 12px;
      background-color: white;
      border: none;
      transform: none;
    }
  }
  &::after {
    content: "";
    position: absolute;
    left: 7px;
    top: 3px;
    width: 5px;
    height: 11px;
    border: solid #43b70d;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
.step-back {
  border-radius: 5px;
  padding: 5px 10px;
  &:disabled {
    cursor: default;
  }
}
.step-confirm {
  padding: 5px 10px;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: white;
  &:disabled {
    opacity: 0.7;
    cursor: default;
  }
}
.step-subtitle {
  font-size: 36px;
  font-weight: 500;
  margin: 0 0 20px 0;
  color: #757575;
}
.step-grid {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 27px;
  max-height: 280px;
}
.step-number {
  display: flex;
  align-items: flex-start;
  margin-right: 22%;
  position: relative;
  font-size: 24px;
  font-weight: 500;
  &.disable::after {
    display: none;
  }
  &::after {
    content: "";
    position: absolute;
    top: 35px;
    height: calc(100% - 50px);
    width: 2px;
    left: 11px;
    border-left: 2px dashed #757575;
  }
}
.step-inside-content {
  margin-bottom: 80px;
}

.check-mark {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: green;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.check-line {
  height: 2px;
  width: 100%;
  margin-right: 20px;
}

.confirmed {
  background-color: green;
}

.step-container {
  width: 100%;
  position: relative;
}

.active {
  pointer-events: all;
}

.inactive {
  pointer-events: none;
  opacity: 0.5;
}

.line.active {
  border-left: 2px dashed black;
}

.finished-message {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: green;
  margin-top: 20px;
}

.step {
  margin-bottom: 30px;
}
.step-title {
  font-size: 24px;
  margin: 0 0 40px 0;
}

@media screen and (max-width: 850px) {
  .step-checkmark{
    margin-right: 10px;
  }
  .step-number-content{
    font-size: 12px;
  }
  .step-number{
    margin-right: 12%;
  }
  .step-title{
    font-size: 18px;
    margin-bottom: 30px;
  }
  .step-grid{
    max-height: none;
    gap: 20px;
  }
}