.container {
  width: 100%;
}
.container > :first-child {
  height: 100%;
}

.arrow {
  width: 52px;
  background: #ffffff;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
  position: absolute;
  padding: 10px 20px;
  color: #00000020;
  font-size: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
  user-select: none;
  border-radius: 10px;
}
.disabled {
  cursor: default;
  background-color: #dddddd;
}

.current {
  left: 0;
  transform: translateX(0);
  opacity: 1;
  transition: all 0.5s;
}
.next {
  transform: translateX(102%);
  opacity: 0.2;
  filter: blur(5px);
  transition: all 0.5s;
}
.old {
  transform: translateX(-102%);
  opacity: 0.2;
  filter: blur(5px);
  transition: all 0.5s;
}
