.content {
  margin-bottom: 80px;
}
.title {
  font-size: 36px;
}
.group {
  grid-template-columns: repeat(auto-fill, 386px);
  display: grid;
  gap: 20px;
}