.profile {
  display: none;
}
.cabinet {
  flex-direction: row-reverse;
  justify-content: start !important;
  gap: 30px !important;
  margin-bottom: 20px;
  margin-top: 10px;
}
.cabinet > :nth-child(2) {
  background-color: #d6d6d6 !important;
}
.legal {
  height: 60px;
  border-radius: 50%;
}
.primary {
  box-sizing: border-box;
  padding: 10px;
  width: 50px;
  height: 50px;
  background-color: var(--primary-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.primary img {
  height: 30px;
}