.internet-body {
  margin-top: 30px;
}
.offline-icon {
  width: 25px;
  height: 25px;
  background-color: rgba(216, 63, 68, 0.31);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    border-radius: 50%;
    width: 40%;
    height: 40%;
    background: #d83f44;
  }
}
.online-icon {
  width: 25px;
  height: 25px;
  background-color: rgba(63, 216, 101, 0.31);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    border-radius: 50%;
    width: 40%;
    height: 40%;
    background: #22e76d;
  }
}
.online-text {
  font-size: 32px;
}
.online-row {
  margin-top: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
}
