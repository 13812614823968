// ** Tags
h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}
p {
  font-size: 14px;
}
h1 {
  font-size: 36px;
}
h3 {
  font-size: 20px;
}
a {
  transition: all 0.3s ease-in;
  color: rgba(128, 128, 128, 0.7);
  text-decoration: none;
  &:hover {
    color: var(--primary-color);
  }
}
button {
  border: none;
  cursor: pointer;
}

// ** Classes
.primary-small-btn {
  font-size: 11px;
  font-weight: 300;
  height: min-content;
  padding: 5px 15px;
  border-radius: 100px;
  background-color: var(--primary-color);
  color: white;
}
.primary-btn {
  background: var(--primary-color);
  border-radius: var(--radius);
  color: white;
  padding: 20px;
  font-size: 18px;
  width: 300px;
  font-weight: 600;
  &.mini {
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 400;
  }
  &.auto {
    width: auto;
  }
  &.light {
    font-weight: 400;
    font-size: 16px;
    padding: 18px;
    height: fit-content;
  }
  &.full {
    width: 100%;
    border: 2px solid #f35459;
  }
  &.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
  }
  &:disabled {
    background-color: #efefef;
    color: #a3a3a3;
    pointer-events: none;
  }
  &:active {
    filter: brightness(1.5);
  }
}
.secondary-btn {
  border: 2px solid var(--primary-color);
  background-color: white;
  border-radius: var(--radius);
  color: black;
  padding: 20px;
  font-size: 18px;
  width: 300px;
  font-weight: 600;
  transition: all 250ms;
  will-change: background-color, color;
  &:hover {
    background-color: var(--primary-color);
    color: white;
  }
  &:disabled {
    border-color: #efefef;
    color: #a3a3a3;
    pointer-events: none;
  }
  &.light {
    font-weight: 400;
    font-size: 16px;
    padding: 18px;
    height: fit-content;
  }
  &.full {
    width: 100%;
  }
  &.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
  }
}
.icon-primary-btn {
  cursor: pointer;
  color: var(--primary-color);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  padding: 10px;
  background-color: transparent;
  text-decoration: underline;
}
.edit-btn {
  cursor: pointer;
  margin-left: 20px;
  // position: absolute;
}
.icon-btn {
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: transparent;
}
.logout-btn {
  background-color: transparent;
  border: none;
  padding: 0;
  transition: all 0.3s ease-in;
  align-items: center;
  display: flex;
  color: hsla(0, 0%, 50%, 0.7);
  font-size: 16px;
  gap: 5px;
  &:hover {
    color: var(--primary-color);
  }
}
.danger-alert {
  color: rgba(229, 71, 76, 1);
  background: rgba(255, 216, 217, 0.65);
  border-radius: 14px;
  height: fit-content;
  padding: 20px 40px;
  font-size: 16px;
}
.unselected {
  background-color: var(--secondary-color);
  color: #43424250;
}

.divider {
  height: 50%;
  margin: 0px 10px;
  width: 1px;
  background-color: rgba(128, 128, 128, 0.2);
}
.divider-dashed {
  background-image: linear-gradient(to right, #696969 33%, rgba(255, 255, 255, 0) 0%);
  background-position: center;
  background-size: 8px 1px;
  background-repeat: repeat-x;

  height: 10px;
  margin: 10px 0px;
  width: 100%;
}
.red-line {
  margin-top: 40px;
  background-color: var(--primary-color);
  color: white;
  padding: 5px 0;
  font-size: 13px;
  .block {
    display: inline-flex;
    align-items: center;
    gap: 2em;
    margin: 0 2em;
  }
}
.modal-div {
  // width: 50%;
  // max-width: 1000px;
  width: fit-content;
}
.social-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  padding: 18px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
  & > img {
    height: 100%;
  }
}

.point {
  cursor: pointer;
  text-decoration: underline;
  display: flex;
  align-items: center;
  gap: 0.5em;
  color: var(--primary-color);
}
.main-block {
  background: rgba(242, 242, 242, 0.5);
  border-radius: var(--radius);
  padding: 20px;
}
.shadow-block {
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  border-radius: var(--radius);
  padding: 30px 20px;
  overflow: auto;
}
.grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.grid-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
}
.frame {
  background: var(--secondary-color);
  border-radius: var(--radius);
  &.center {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
  }
  &.br {
    padding: 20px 55px;
    font-size: 22px;
    border-radius: 21px;
    text-align: center;
    & .checkmark {
      transition: all 0.1s ease-out;
      width: 35px;
      height: 35px;
      background-color: #e5ffd9;
      border: 1px solid #43b70d;
      border-radius: 50%;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        left: 10px;
        top: 5px;
        width: 9px;
        height: 15px;
        border: solid #43b70d;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
  &.space {
    display: flex;
    gap: 10px;
    padding: 10px 20px;
  }
  &.succ {
    background-color: #cbffb280;
  }
  &.red {
    background-color: rgb(255, 166, 166);
  }
  // transition: all 0.3s ease;
}
.primary-frame {
  background: rgb(255 204 206) !important;
  border-radius: var(--radius);
}
.additional-frame {
  background-color: #fff2f2;
  border-radius: 21px;
  color: #8b8b8b;
  padding: 30px 55px;
  &.big {
    font-size: 18px;
    color: #363636;
    padding: 25px 35px;
  }
}

.hover_me {
  transition: 0.2s;
  cursor: pointer;
  border: 1px solid transparent;
}

.hover_me:hover {
  transform: scale(1.02);
  border: 1px solid #f35459;
}

.badge {
  padding: 8px 20px;
  border-radius: 30px;
  font-size: 15px !important;
  height: fit-content;
  &.red {
    color: var(--primary-color);
    background-color: rgb(255, 166, 166);
  }
  &.green {
    color: #43b70d;
    background-color: #cbffb280;
  }
}

.list-note {
  color: #7e7e7e;
  font-size: 20px;
  list-style-type: none;
  padding: 0;
  margin-top: 30px;
  & > li {
    position: relative;
    margin-bottom: 30px;
    padding-left: 50px;
    &::before {
      content: '*';
      position: absolute;
      color: var(--primary-color);
      font-size: 50px;
      left: 0;
      top: 0;
      transform: translateY(-6px);
      height: -webkit-fill-available;
    }
  }
}
.note-sym {
  position: relative;
  padding-left: 30px;
  margin: 0;
  &::before {
    content: '*';
    position: absolute;
    color: var(--primary-color);
    font-size: 50px;
    left: 0;
    top: 0;
    transform: translateY(-6px);
    height: -webkit-fill-available;
  }
}
// ** Texts
.primary {
  color: var(--primary-color);
}
.bold {
  font-weight: 700 !important;
}
.regular {
  font-weight: 400;
  font-size: 20px;
}
.mini-t {
  color: #757575;
  line-height: 22px;
  font-size: 14px !important;
  &.s13 {
    font-size: 13px !important;
    line-height: normal !important;
  }
}
.balance-text {
  font-size: 38px;
  color: black;
  font-weight: 400;
}
.succ-t {
  color: #43b70d;
}
.succes-text {
  color: #363636;
  font-weight: 400;
  font-size: 20px;
}
.unfocus-text {
  color: rgba(43, 44, 50, 0.4);
}
.unfocus-text2 {
  color: #7e7e7e;
}
.f-600 {
  font-weight: 600;
}
.red-text {
  color: var(--primary-color) !important;
  &.big {
    font-size: 32px;
  }
}
.email-text {
  color: var(--primary-color);
  font-size: 20px;
}
.phone-text {
  display: flex;
  justify-content: center;
  gap: 8px;
  color: black;
}
.t-start {
  text-align: start;
}
.t-center {
  text-align: center;
}
.red-image {
  color: var(--primary-color);
  // & path {
  //   fill: var(--primary-color);
  // }
}
.table-item {
  display: flex;
  align-items: center;
  gap: 10px;
}
.unfocus-t {
  font-size: 13px;
  color: #757575;
}
.error-text {
  text-align: center;
  font-size: 35px;
  color: var(--primary-color);
}
.error-text1 {
  text-align: center;
  font-size: 30px;
}
.center-pos-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
}

// ** Margins
.mb-1 {
  margin-bottom: 1em;
}

// ** Flex
.row {
  display: flex;
  &.end {
    justify-content: flex-end;
  }
  &.center {
    justify-content: center;
  }
  &.block {
    gap: 1.5em;
    position: relative;
  }
}
.row-m {
  display: flex;
  &.center {
    align-items: center;
  }
}
.row-m-r {
  display: flex;
}
.full-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  &.each-equal {
    * {
      width: 95%;
    }
  }
}
.full-row-items {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
  & > * {
    width: 100%;
  }
}
.col {
  display: flex;
  flex-direction: column;
  &.center {
    align-items: center;
  }
  &.block {
    gap: 1.5em;
  }
  &.end {
    align-items: flex-end;
  }
  &.full {
    width: 100%;
  }
  &.full-h {
    height: 100%;
  }
}
.a-center {
  align-items: center;
  display: flex;
}
.j-center {
  display: flex;
  justify-content: center;
}
.between {
  display: flex;
  justify-content: space-between;
}
.between-cab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: 20px;
  }
  & > :first-child {
    font-size: 20px;
    color: #757575;
  }
  &.end {
    align-items: flex-end;
  }
}
.g-1 {
  gap: 5px;
}
.g-2 {
  gap: 1em;
}
.g-3 {
  gap: 2em;
}
.g-4 {
  gap: 3em;
}
.g-5 {
  gap: 5em;
}
.p-1 {
  padding: 1em;
}

.bring-friend-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  & input {
    width: 300px;
  }
}
@media screen and (max-width: 1170px) {
  .fullButton{
    width: 100%;
  }
}
@media screen and (max-width: 1140px) {
  .bring-friend-row {
    // flex-direction: column;
    // gap: 20px;
    & input {
      width: 250px;
    }
  }
  .bring-container {
    width: 850px !important;
  }
}
@media screen and (max-width: 1045px) {
  .bring-friend-row {
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
    & input {
      width: calc(100vw - 110px);
    }
  }
}
@media screen and (max-width: 1225px) {
  h1 {
    font-size: 32px;
    text-align: center;
  }
  .between-cab {
    p {
      font-size: 16px;
    }
    & > :first-child {
      font-size: 16px;
    }
  }
  .frame {
    font-size: 14px;
    &.space {
      padding: 8px 10px;
    }
  }
  .regular {
    font-size: 18px;
  }
  .mini-t {
    font-size: 11px !important;
    width: max-content;
  }
}
@media screen and (max-width: 850px) {
  .phone-text {
    font-size: 14px;
  }
  .bring-container {
    width: 90% !important;
  }
  .bring-container img{
    display: none;
  }
  .secondary-small-btn {
    font-size: 11px;
    font-weight: 400;
    height: min-content;
    padding: 5px 15px;
    border-radius: 100px;
    color: #696969;
  }
  .row-m {
    flex-direction: column;
  }
  .row-m-r {
    flex-direction: column-reverse;
  }
  .col {
    &.mob {
      flex-direction: row;
      align-items: center;
    }
    &.cab {
      display: grid;
      grid-template-columns: 65% 30%;
    }
  }
  .between-cab {
    justify-content: flex-start;
    width: fit-content;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5em;
  }
  .unfocus-text2 {
    &.big {
      font-size: 15px;
    }
  }
  .primary-small-btn {
    background: rgba(243, 84, 89, 0.1);
    color: var(--primary-color);
    font-weight: 400;
    font-size: 12px;
  }
  .divider {
    min-height: 40px;
  }
  .row {
    &.block {
      flex-direction: column;
    }
  }
  .edit-btn {
    margin-left: 10px;
  }
  .g-3 {
    gap: 1.2em;
  }
  h1 {
    font-size: 24px;
    text-align: center;
    margin: 0px !important;
  }
  h3 {
    font-size: 16px;
  }
  .b24-widget-button-position-bottom-right {
    right: 30px !important;
    bottom: 100px !important;
  }
  .balance-text {
    font-size: 24px;
  }
  .icon-primary-btn {
    padding: 10px 5px;
  }
  .modal-div {
    opacity: 1;
    transform: none;
    width: fit-content;
    margin: 0 20px;
    margin-bottom: 200px;
  }
  .danger-alert {
    padding: 15px 30px;
    font-size: 14px;
  }
  .additional-frame {
    padding: 15px 25px;
    font-size: 14px;
    border-radius: 10px;
  }
  .note-sym {
    padding-left: 25px;
    &::before {
      font-size: 35px;
    }
  }
  .full-row {
    &.mob {
      flex-direction: column;
      gap: 20px;
    }
  }
  .list-note {
    font-size: 14px;
    li {
      padding-left: 30px;
      &::before {
        font-size: 35px;
      }
    }
  }
}

.show-missing-amount {
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: rgba(243, 84, 89, 1);
  cursor: pointer;

  &:hover .missing-amount-for-next-month {
    opacity: 1;
    transition: 0.5s;
  }

  .show-next-month-amount {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;

    .svg {
      position: absolute;
      bottom: 40px;
      right: 10px;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 25px solid transparent;
      border-right: 25px solid transparent;
      border-top: 35px solid #fff2f2;
      border-radius: 5px;
      z-index: 15;
      transition: 0.5s;
      opacity: 0;
    }

    &:hover > .svg {
      opacity: 1;
    }
  }
}

.missing-amount-for-next-month {
  position: absolute;
  bottom: 120px;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;

  &:after {
    content: '';
    position: absolute;
    bottom: 85px;
    right: 10px;
    width: 95%;
    height: 50px;
    background: white;
    border-radius: 5px;
    z-index: -1;
  }

  &_item {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 9px;
    background: #fff2f2;
    color: #000;
    padding: 25px;
    box-shadow: 0px 4px 20px 1px #f0cdcf;
  }
}

@media screen and (max-width: 850px) {
  .show-next-month-amount:hover > .svg {
    position: absolute;
    bottom: 40px;
    right: -10px;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-top: 35px solid #fff2f2;
    border-radius: 5px;
    z-index: 15;
    opacity: 0;
  }
}

@media only screen and (max-width: 768px) {
  .hover_me {
    cursor: pointer;
    border: none;
  }
  .bring-friend_input{
    width: calc(90vw - 55px) !important;
  }
  .bring-friend-row input{
    width: 90vw ;
  }

  .hover_me:hover {
    transform: none;
    border: none;
  }
}
