.wrap {
  display: flex;
  align-items: center;
  gap: 15px;

  font-size: 20px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.input:checked ~ .checkmark {
  background-color: #ececec;
}
.input:checked ~ .checkmark:after {
  display: block;
}
.low {
  color: #757575;
  font-weight: 400;
}
.checkmark {
  transition: all 0.1s ease-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 22px;
  height: 22px;
  background-color: #ececec;
  border-radius: 4px;
  top: 50%;
  transform: translateY(-50%);
  /* cursor: pointer; */
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 11px;
  border: solid #757575;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media screen and (max-width: 850px) {
  .wrap{
    font-size: 14px;
  }
}