.phone-edit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > .btns {
    display: flex;
    gap: 10px;
    & > img {
      height: 20px;
      cursor: pointer;
    }
  }
  &.changed {
    background-color: rgb(214, 255, 214);
  }
}
.phone-input {
  margin: 0 !important;
  background-color: transparent !important;
  border: none !important;
  border-radius: var(--radius) !important;
  padding: 10px !important;
  width: 100% !important;
  &.editable {
    text-decoration: underline;
  }
}
.cabinet {
  position: relative;
}
.cabinet-header {
  width: -webkit-fill-available;
  z-index: 1000;
  position: relative;
  & > .container {
    padding: 0;
    display: flex;
  }
}
.mobile-dropdown-content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100000;
  & > .wrap {
    width: 100%;
    position: absolute;
    background-color: white;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
    z-index: 10;
    border-radius: var(--radius) var(--radius) 0 0;
    left: 50%;
    transform: translateX(-50%);
    bottom: 79px;
    padding: 15px 20px;
    & > :first-child {
      border-radius: var(--radius);
      overflow: hidden;
    }

    .menu-item-mob {
      margin: 15px 0;
      opacity: 0;
      transition: all 200ms !important;
      transform: translateY(-30px);
    }
    .menu-item-text {
      color: black;
      background-color: #f2f2f2;
      padding: 20px 16px;
      text-decoration: none;
      display: block;
      border-radius: 9px;
      transition: none;
      display: flex;
      gap: 10px;
      align-items: center;
      svg {
        transform: scale(0.8);
      }
      &:hover {
        background-color: var(--primary-color);
        color: white !important;
        path {
          fill: white;
        }
      }
    }
  }
}
.cabinet-dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  color: #696969;
  background: transparent;
  text-align: start;
  cursor: default;
  width: 100%;
  height: 100%;
}
.dropdown-wrapper {
  --height-dropdown: 80px;
  width: inherit;
  height: var(--height-dropdown);
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 3px solid #f6f6f6;
  position: relative;

  & > .dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
    z-index: 100;
    top: calc(100% + 4px);
    width: 100%;
    // left: 0;
    &.btn > .space {
      & > button {
        cursor: pointer !important;
      }
      cursor: pointer !important;
    }
    & > .space {
      display: flex;
      position: relative;
      width: inherit;
      padding: 0px 10px;
      height: 80px;
      align-items: center;
      justify-content: center;
    }
    // transform: translateY(100%);
    border-radius: 0px 0px 10px 10px;
    overflow: hidden;
    .dropdown-link {
      color: black;
      text-decoration: none;
      display: block;
      border-bottom: 1px solid rgba(0, 0, 0, 0.075);
      // opacity: 0;
      transform: translateY(15px);
      transition: all 200ms !important;
      .dropdown-link-text {
        display: flex;
        gap: 20px;
        align-items: center;
        transition: none !important;
        width: 100%;
        height: 100%;
        padding: 20px 16px;
        font-size: 16px;
        &:hover {
          background-color: var(--primary-color);
          color: white !important;
          path {
            fill: white !important;
          }
        }
      }
    }
  }

  &.special {
    cursor: pointer;
    .cabinet-dropdown {
      cursor: pointer;
    }
  }
  &:hover {
    // background-color: white;
    border-bottom: 4px solid var(--primary-color);
    & > button {
      color: var(--primary-color);
    }
    &:not(.special) .dropdown-content {
      display: block;
    }
  }
}
.red-image {
  border-bottom: 4px solid var(--primary-color);
  & > button {
    color: var(--primary-color);
  }
}
.dropdown-link-appear,
.dropdown-link-enter {
  opacity: 0 !important;
}
.dropdown-link-enter-done {
  opacity: 1 !important;
  transform: translate(0) !important;
}
.dropdown-link-appear-active,
.dropdown-link-enter-active {
  opacity: 1;
  // --val: 200ms !important;
  // transition: transform var(--val), opacity var(--val);
}
.dropdown-link-exit {
  opacity: 1;
}
.dropdown-link-exit-active {
  opacity: 0;
  --val: 10ms !important;
  transition: transform var(--val), opacity var(--val);
}
.cabinet-body {
  z-index: -2;
  width: 100%;
  padding: 20px 0px 40px 0;
  & > .container {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  p {
    font-size: 20px;
    font-weight: 400;
  }
}
.info-tariff {
  display: flex;
  gap: 15px;
  & > div {
    width: 100%;
  }
  & > .col {
    display: flex;
    flex-direction: column;
    gap: 8px;
    & > div {
      height: 100%;
      padding: 10px 15px;
      & > .row {
        align-items: flex-end;
      }
    }
  }
}
.main-info {
  background-color: var(--secondary-color);
  border-radius: 10px;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 15px;
  width: min-content;
  .mobile-ver {
    display: none;
  }
  & > p {
    width: 80%;
    font-size: 18px;
  }
  & > div {
    justify-content: space-between;
    gap: 30px;
    align-items: center;
  }
}
.primary-info {
  background-color: #f35459;
  color: white;
  width: auto;
  position: relative;
  & > .row {
    justify-content: right;
  }
  img {
    position: absolute;
    left: 10px;
    top: 15px;
  }
  & > p {
    width: 100%;
  }
  .main-add {
    color: white;
    font-weight: 600;
    font-size: 18px;
    width: max-content;
  }
}
.main-text {
  font-size: 55px;
  font-weight: 300;
  color: var(--primary-color);
}
.main-desc {
  width: max-content;
  float: right;
}
.main-add {
  color: var(--primary-color);
  font-weight: 400;
}

@media screen and (max-width: 850px) {
  .cabinet-body {
    p {
      font-size: 12px;
    }
  }
  .dropdown-wrapper {
    --height-dropdown: 79px;
    border: none !important;
  }
  .cabinet-dropdown {
    transition: transform 0.1s ease-out;
    &:active {
      transform: scale(0.7);
    }
  }
  .red-image {
    border: none;
    .cabinet-dropdown {
      &::before {
        content: "";
        background-color: rgba(243, 84, 89, 0.05);
        border-radius: 50%;
        height: 60px;
        width: 60px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
      }
      position: relative;
    }
    svg {
      transform: scale(1.4);
    }
    path {
      fill: var(--primary-color);
    }
  }
  .cabinet-header {
    width: -webkit-fill-available;
    z-index: 100000;
    position: fixed;
    display: flex;
    bottom: 0;
    background-color: white;
    box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.08);
  }
}

.toUp-appear {
  transform: translateX(-50%) translateY(100%) !important;
}

.toUp-appear.toUp-appear-active {
  transform: translateX(-50%) translateY(0) !important;
  transition: transform 200ms ease;
}

.toUpDropDown-appear {
  transform: translateY(200%) !important;
}

.toUpDropDown-appear.toUpDropDown-appear-active {
  transform: translateY(calc(100% - 213px)) !important;
  transition: transform 450ms ease;
}
