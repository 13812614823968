.terminate-contract {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 80px !important;
}
.a-c {
  align-self: center;
}
.error-mark {
  transition: all 0.1s ease-out;
  width: 35px;
  height: 35px;
  background-color: #ffd9d9;
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  position: relative;
  // &::after {
  //   content: "";
  //   position: absolute;
  //   left: 10px;
  //   top: 5px;
  //   width: 9px;
  //   height: 15px;
  //   border: solid var(--primary-color);
  //   border-width: 0 3px 3px 0;
  //   -webkit-transform: rotate(45deg);
  //   -ms-transform: rotate(45deg);
  //   transform: rotate(45deg);
  // }
  &::before,
  &::after {
    position: absolute;
    left: 15px;
    content: " ";
    top: 7px;
    height: 20px;
    width: 2px;
    background-color: var(--primary-color);
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
}
