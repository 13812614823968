.SingleNews {
  text-align: left;
  flex: 1;
  margin-top: 40px;
}

.SingleNews__inner {
  max-width: 1200px;
  margin: 0 auto 110px;
}

.SingleNews__headTitle {
  margin-top: 0;
  margin-bottom: 50px;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 40px;
  color: #000000;
}

.SingleNews__headTitle span {
  color: #e6474c;
  display: block;
  transition: all 0.3s ease-in;
}

.SingleNews__items {
  display: flex;
  flex-wrap: wrap;
  margin-left: -100px;
}

.SingleNews__left {
  flex: 1;
  max-width: 100%;
  padding: 0 0 100px 100px;
}

.SingleNews__left a:hover {
  text-decoration: underline;
}

.SingleNews__right {
  flex: 0 0 388px;
  max-width: 388px;
  padding: 0 0 100px 100px;
  box-sizing: content-box;
}

.SingleNews__content {
  margin-bottom: 50px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.SingleNews__content p {
  margin: 0;
}

.SingleNews__content p+p {
  margin-top: 20px;
}

.SingleNews__orderTitle {
  margin: 0 0 30px;
  font-style: normal;
  font-size: 17px;
  line-height: 30px;
  color: #000000;
}

.SingleNews__orderTitle a {
  color: #e6474c;
  display: contents;
}

.SingleNews__order {
  display: flex;
  margin-bottom: 25px;
}

.SingleNews__orderIcon {
  margin-top: 10px;
  flex: 0 0 20px;
  max-width: 20px;
}

.SingleNews__orderText {
  flex: 1;
  margin-left: 35px;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
}

.SingleNews__promotionTitle {
  margin: 0 0 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 60px;
  color: #000000;
}

@media (max-width: 1210px) {
  .SingleNews__inner {
    padding: 0 20px;
  }
}

@media (max-width: 991px) {

  .SingleNews__headTitle,
  .SingleNews__promotionTitle {
    margin-bottom: 20px;
    font-size: 28px;
    line-height: 30px;
  }

  .SingleNews__items {
    display: block;
    margin: 0;
  }

  .SingleNews__left,
  .SingleNews__right {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 0 50px 0;
  }

  .SingleNews__inner {
    margin-bottom: 40px;
  }

  .SingleNews__items {
    position: relative;
    display: flex;
    margin-bottom: 100px;
  }

  .SingleNews__right {
    order: 1;
    padding-bottom: 0;
  }

  .SingleNews__left {
    order: 2;
  }
}

@media (max-width: 600px) {
  .SingleNews__content {
    font-size: 15px;
    line-height: 18px;
  }
  .iframeYoutube iframe{
    width: 300px;
  }


  .SingleNews__left,
  .SingleNews__right {
    padding-bottom: 20px;
  }

  .SingleNews__orderTitle>p:nth-of-type(3)>img {
    width: 280px !important;
    max-width: none;
    height: 143px;
  }

  .SingleNews__orderTitle>p:nth-of-type(4)>img {
    width: 280px !important;
    max-width: none;
  }

  .SingleNews__orderTitle>p:nth-of-type(5)>img {
    width: 280px !important;
    max-width: none;
  }

  .SingleNews__orderTitle>p:nth-of-type(3) {
    display: flex;
    justify-content: center;
  }

  .SingleNews__orderTitle>p:nth-of-type(4) {
    display: flex;
    justify-content: center;
  }

  .SingleNews__orderTitle>p:nth-of-type(5) {
    display: flex;
    justify-content: center;
  }



  .SingleNews__orderText {
    font-size: 13px;
    line-height: 14px;
  }

  .SingleNews__items {
    position: relative;
    display: flex;
    margin-bottom: 100px;
  }

  .SingleNews__right {
    order: 1;
    padding-bottom: 0;
  }

  .SingleNews__left {
    order: 2;
  }
}