.main_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
}

/* BANNER */
.main_banner_wrapper {
  background: #fafafa;
  padding: 20px 20px 30px 20px;
  width: 100%;
}

.main_banner {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 15px 0px;
  height: 100%;
  display: grid;
  grid-template-columns: 50% minmax(100px, 100%);
}

.right_side {
  display: flex;
  align-items: flex-end;
}

.right_side > img {
  width: 100%;
}

.left_side {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.left_side > h2 {
  font-size: 40px;
}

.left_side > p {
  font-size: 18px;
  line-height: 25px;
}

.anchor_btns {
  display: flex;
  gap: 20px;
}

.anchor_btns > button {
  padding: 15px 20px;
  border: 1px solid #e5474c;
  background: white;
  border-radius: 50px;
  color: #e5474c;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
}

.anchor_btns > button:last-child {
  background: #e5474c;
  color: #ffffff;
}

/* .rocket_scroll_to_top {
  background: #f35459;
  position: fixed;
  bottom: 1rem;
  right: 3rem;
  padding: 12px 22px 6px 22px;
  border-radius: 100%;
  cursor: pointer;
} */

@media screen and (max-width: 930px) {
  .main_banner_wrapper {
    background: #fafafa;
    padding: 20px 20px 30px 40px;
  }

  .right_side > img {
    width: auto;
  }
}

@media screen and (max-width: 740px) {
  .main_banner_wrapper {
    background: #fafafa;
    padding: 20px 20px 30px 20px;
  }

  .main_banner {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 15px 0px;
    height: 100%;
  }

  .left_side {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .left_side > h2 {
    font-size: clamp(28px, 5vw, 33px);
    text-align: center;
  }

  .left_side > p {
    font-size: 18px;
    line-height: 25px;
  }

  .left_side > img {
    width: 100%;
  }
}

@media screen and (max-width: 440px) {
  .anchor_btns {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }

  .anchor_btns > button {
    color: #e5474c;
    font-weight: 500;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
  }
}

/* --- BANNER --- END */

/* YANDEX DNS */
.yandex_dns_wrap {
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  min-height: 500px;
  padding: 40px 20px;
  flex-direction: column;
  gap: 40px;
}

.yandex_dns_wrap > h2 {
  text-align: center;
}

.yandex_dns_wrap > h2 {
  font-size: 40px;
  margin-bottom: 10px;
}

.settings_wrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 25px;
}

.setting_item {
  padding: 25px;
  height: 215px;
  max-width: 285px;
  width: 100%;
  box-shadow: -2px 0px 17px 0px #00000013;
  border-radius: 15px;
}

.setting_item > h3 {
  font-size: 24px;
  color: #f35459;
  margin-bottom: 18px;
}

.setting_item_inside_wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 5px 20px;
}

.conditions_wrap {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
}

.condition_item {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.condition_item > img {
  width: 50px;
}

.condition_item > h3 {
  font-size: 24px;
}

.condition_item > p {
  font-size: 18px;
  color: #898b96;
  line-height: 23px;
}

.connect_service_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.connect_service_wrap > p {
  text-align: center;
  font-size: 20px;
  line-height: 28px;
  max-width: 800px;
}

.connect_service_wrap > button {
  padding: 20px 15px;
  font-size: 18px;
  border-radius: 10px;
  background: #f35459;
  color: #fff;
  max-width: 350px;
  width: 100%;
}

.about_dns_wrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.about_dns_item {
  margin-top: 10px;
}

.about_dns_item > h3 {
  font-size: 30px;
}

.about_dns_descr_wrap {
  margin-top: 15px;
  gap: 15px 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.about_dns_descr_wrap > p {
  font-size: 20px;
  line-height: 28px;
  max-width: 550px;
}

@media screen and (max-width: 960px) {
  .settings_wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
  }

  .setting_item {
    min-width: 100%;
  }

  .conditions_wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 590px) {
  .settings_wrap {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 25px;
  }
  .setting_item {
    min-width: 100%;
    height: auto;
  }

  .conditions_wrap {
    grid-template-columns: repeat(1, 1fr);
  }

  .about_dns_descr_wrap {
    gap: 30px 50px;
    grid-template-columns: repeat(1, 1fr);
  }
}

/* --- YANDEX DNS --- END */
