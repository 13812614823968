.dropdown {
  position: relative;
  height: fit-content;
}
.button {
  background: #f7f7f7;
  border-radius: var(--radius);
  font-size: 20px;
  width: 100%;
  // width: 200px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding: 20px 30px;
  gap: 15px;
  &.primary {
    background-color: white;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
    font-size: 20px;
    width: 100%;
    text-overflow: ellipsis;
    clear: both;
    overflow: hidden;
    white-space: nowrap;
  }
}
.button img {
  height: 10px;
}
.wrapLabel {
  text-align: start;
  &.primary {
    width: 100%;
  }
}
.wrap button {
  // border-bottom-left-radius: 0;
  // border-bottom-right-radius: 0;
}
.wrap a {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.wrap a:last-child {
  border: none;
}
.a {
  transition: all 0.2s;
  padding: 18px 30px;
  background-color: white;
  font-size: 20px;
  color: black;
  cursor: pointer;
}
.wrap {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  // overflow: hidden;
  border-radius: var(--radius);
  &.primary {
    width: 100%;
    .button {
      width: 100%;
    }
  }
}
.itemsWrapper {
  display: flex;
  flex-direction: column;
  max-height: 400px;
  width: inherit;
  // width: max-content;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(100%);
  box-shadow: 0px 4px 30px rgb(0 0 0 / 8%);
  border-radius: var(--radius);
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
}

