/* @import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */
@import url('../assets/fonts/Rubik/stylesheet.css');

:root {
  --color: white;

  --primary-color: #f35459;
  --secondary-color: #f6f6f6;
  --input-radius: 9px;
  --radius: 10px;
}
path,
pattern {
  shape-rendering: geometricPrecision;
}
* {
  font-family: 'Rubik', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;

  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
*:focus {
  outline: none;
}
body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar {
  width: 10px;
}
body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.hide-chat .b24-widget-button-wrapper {
  display: none;
}
.b24-widget-button-inner-block,
.b24-widget-button-inner-mask,
.b24-widget-button-pulse,
.b24-widget-button-social-item,
.bx-livechat-head,
.b24-form-btn,
.b24-window-close {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  overflow: hidden;
}
.container {
  width: 1200px;
  margin: 0 auto;
  padding: 15px 0px;
  height: 100%;
  flex: 2;
  /* overflow: hidden; */
}
.container-min {
  width: 1100px;
  margin: 0 auto;
  padding: 15px 0;
  padding-bottom: 80px;
  height: 100%;
  flex: 2;
}
.top-info {
  width: 100%;
  justify-content: space-between;
  max-width: 70%;
  margin: 0 auto;
  margin-top: 10px;
}
.top-info span {
  margin-right: 10px;
}

.report-table tbody > tr > td:first-child {
  max-width: 250px;
  word-break: keep-all;
  overflow: hidden;
  overflow-wrap: break-word;
  white-space: break-spaces;
  hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  /* -webkit-hyphenate-character: "=";
  hyphenate-character: "="; */
}

.page-tr-appear,
.page-tr-enter {
  opacity: 0;
  z-index: 1;
}
.page-tr-appear-active,
.page-tr-enter.page-tr-enter-active {
  opacity: 1;
  transition: opacity 450ms linear 250ms;
}
.page-tr-exit {
  opacity: 1;
}
.page-tr-exit.page-tr-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
}

.rocket-scroll-to-top {
  right: 55px !important;
  bottom: 140px !important;
}

@media screen and (max-width: 1225px) {
  .container {
    width: 1100px;
  }
  .container > h1 {
    text-align: center;
  }
}

@media screen and (max-width: 1150px) {
  .container {
    width: 90%;
    max-width: 1100px;
  }
}

@media screen and (max-width: 850px) {
  .container-min {
    max-width: 1100px;
    width: 85%;
    position: relative;
  }
  .container-min.marg {
    padding: 40px 0 50px 0 !important;
  }
  .rocket-scroll-to-top {
    right: 36px !important;
    bottom: 190px !important;
  }
}
