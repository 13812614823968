.wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.extra_info {
  position: absolute;
  transition: opacity 0.3s;
  background: #fff2f2;
  right: 450px;
  top: -30px;
  padding: 15px;
  border-radius: 8px;
  color: #a5a5a5;
  opacity: 0;
  max-height: 400px;
}

.extra_info > p {
  font-size: 16px;
}

.arrow_extra_info {
  position: absolute;
  transition: opacity 0.3s;
}

.arrow_extra_info:before {
  content: '';
  background: #fff2f2;
  position: absolute;
  left: -75px;
  top: -33px;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  transform: rotate(45deg);
  z-index: -1;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  width: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b1b1b1;
  border: 3px solid transparent;
  border-radius: 10px;
  background-clip: content-box;
}

@media screen and (max-width: 850px) {
  .extra_info {
    position: absolute;
    transition: 0.3s;
    background: #fff2f2;
    left: -50%;
    transform: translate(50%);
    top: 245px;
    padding: 15px;
    border-radius: 8px;
    color: #a5a5a5;
    opacity: 0;
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    max-height: 190px;
  }

  .arrow_extra_info:before {
    display: none;
  }
}
