.service_wrap {
  display: flex;
  justify-content: flex-start;
  gap: 50px;
  margin: 30px 0 0;
}

.sidebar {
  /* border: 2px solid red; */
}

.hover_item:hover {
  transform: scale(1.03);
  transition: all 0.3s;
  cursor: pointer;
}

.left_side {
  /* width: 50%; */
  width: min-content;
}

.right_side {
  /* width: 100%; */
}

.left_content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 20px;
  background: #f9f9f9;
  padding: 30px 35px 25px 35px;
}

.svg_wrap svg {
  width: 100%;
}

.info_text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 170%;
  color: #898b96;
}

/* .text_justify {
  text-align: justify;
} */

.service_price {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 60px;
  letter-spacing: -0.408px;
  color: rgba(0, 0, 0, 0.8);
  gap: 20px;
  white-space: nowrap;
}

.service_price span {
  font-style: normal;
  font-weight: bold;
  font-size: 65px;
  line-height: 60px;
  letter-spacing: -0.408px;
  color: rgba(0, 0, 0, 0.8);
}

/* RIGHT_SIDE */
.left_content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* padding: 45px; */
}

.main_title {
  color: #000;
  font-family: Rubik;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
}

.about {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px 0 40px 0;
}

.title {
  color: #898b96;
  font-size: 20px;
}

.descr {
  color: #898b96;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
  font-size: 16px;
}

.connect_btn {
  background: #f35459;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  margin-top: 25px;
}

.connect_btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.payment_warning {
  color: #898b96;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
  font-size: 16px;
  color: #f35459;
}

.active_tag[data-active-tag]::before {
  content: attr(data-active-tag);
  position: absolute;
  font-size: 13px;
  background: #cbffb2;
  border-radius: 20px 0;
  padding: 8px 20px;
  color: #43b70d;
  font-weight: 500;
  top: 0px;
  left: 0;
}

@media (max-width: 900px) {
  .service_wrap {
    flex-wrap: wrap;
  }

  .left_content {
    padding: 20px;
  }

  .left_side {
    /* width: 50%; */
    width: 100%;
  }

  .svg_wrap > img {
    width: 100%;
  }
}
