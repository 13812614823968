.background-login {
  background-image: url(../assets/images/login-bg.png);
  background-repeat: no-repeat;
  flex: 1;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  display: flex;
  justify-content: center;
}

.main-block-login {
  margin: 120px 0;
  // & > :first-child {
  //   padding-bottom: 70px;
  //   padding-left: 40px;
  //   padding-right: 40px;
  // }
}
.container-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  align-items: stretch;
  & > h1 {
    text-align: center;
  }
  & > .primary-btn {
    margin-top: 20px;
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .container-login {
    gap: 20px;
  }
  .main-block-login {
    margin: 40px 0;
    & > :first-child {
      padding-bottom: 50px;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 30px;
    }
  }
  .background-login {
    background-size: auto;
  }
}
