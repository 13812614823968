.InternetAccordion:focus,
.react-tabs__tab-panel:focus,
.accordion:focus,
.accordion__item:focus,
.accordion__heading:focus,
.accordion__button:focus {
  outline: none;
}
.InternetAccordion{
  margin-bottom: 80px;
}
.scroll {
  overflow: scroll;
}

.accordion {
  border: none;
}

.accordion__item {
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
  border-radius: 18px;
  border: none;
  user-select: none;
}

.accordion__button {
  position: relative;
  background: none;
  border-radius: 18px;
  padding: 22px 50px 26px;
}

.accordion__button[aria-expanded='true'] {
  border-radius: 18px 18px 0 0 !important;
}

.accordion__button[aria-expanded='true'] .InternetAccordion__headTitle {
  color: #e5474c;
}

.accordion__item + .accordion__item {
  margin-top: 15px;
  border: none;
}

.accordion__button:hover {
  background-color: #d0d0d0;
}
.accordion__button:hover .InternetAccordion__headTitle,
.accordion__button:hover .InternetAccordion__headSubtitle {
  color: #ffffff;
}

.accordion__button:hover .accordion__button:focus {
  outline: none;
}

.accordion__button:before {
  content: '';
  position: absolute;
  top: 21px;
  right: 46px;
  width: 26px;
  height: 50px;
  margin-right: 0;
  border: none;
  transform: rotate(0deg);
  background: url('../assets/icons/accordion__button.svg') 50% 50% no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  content: '';
  width: 26px;
  height: 50px;
  transform: rotate(0deg);
  background: url('../assets/icons/accordion__button_exp.svg') 50% 50% no-repeat;
}

.InternetAccordion__headTitle {
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  color: #000000;
}

.InternetAccordion__headSubtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #cbccd6;
}

.InternetAccordion__panelTariff {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.InternetAccordion__panelTariff:first-child .border-style {
  height: 100%;
}

.border-style {
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  padding: 5px 10px;
  text-align: center;
  border-bottom: 1px solid #cbccd6;
  background: #f1f1f1;
  border-radius: 15px;
}

.border-style:last-child {
  margin-bottom: 0;
}

.line-through {
  display: inline-block;
  margin-right: 3px;
  text-decoration: line-through;
}

.margin-top {
  margin-top: 30px;
}

.InternetAccordion__panelTitle,
.InternetAccordion__panelTariff {
  padding: 0 0 0 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.accordion__item .InternetAccordion__panelTitle,
.accordion__item .InternetAccordion__panelTariff {
  flex: 0 0 14.28%;
  max-width: 14.28%;
}

.accordion__item.two .InternetAccordion__panelTitle,
.accordion__item.two .InternetAccordion__panelTariff,
.accordion__item.four .InternetAccordion__panelTitle,
.accordion__item.four .InternetAccordion__panelTariff,
.accordion__item.five .InternetAccordion__panelTitle,
.accordion__item.five .InternetAccordion__panelTariff,
.accordion__item.six .InternetAccordion__panelTitle,
.accordion__item.six .InternetAccordion__panelTariff,
.InternetAccordion__panelTitlesWr.margin-top .InternetAccordion__panelTitle,
.InternetAccordion__panelTitlesWr.margin-top + .InternetAccordion__panelTariffsWr .InternetAccordion__panelTariff {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.InternetAccordion__panelTitlesWr {
  margin-bottom: 30px;
  padding: 0 45px;
}

.InternetAccordion__panelTariffsWr {
  margin-bottom: 15px;
  padding: 0 25px;
}

.accordion__panel {
  /* display: block; */
  overflow-y: auto;
  /* overflow-x: hidden; */
  width: 100%;
  /* white-space: nowrap; */
}

.InternetAccordion__panel {
  width: 1160px;
  white-space: break-spaces;
}

.InternetAccordion__panelTitles,
.InternetAccordion__panelTariffs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -20px;
}

/* .InternetAccordion__panelTitle,
.InternetAccordion__panelTariff {
  white-space: break-spaces;
} */

.InternetAccordion__panelTariffsWr.gray {
  background: rgba(196, 196, 196, 0.1);
  border-radius: 10px;
}

.InternetAccordion__panelTariffs {
  padding: 18px 0;
}

.InternetAccordion__panelTariff {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.InternetAccordion__panelInfo {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

/* .accordion__item.three .InternetAccordion__panelTariff,
.accordion__item.seven .InternetAccordion__panelTariff {
  padding: 10px 0;
} */

.accordion__panel {
  animation-name: fadeIn;
  animation-duration: 300ms;
  animation-timing-function: linear;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    /* transform: translateY(-15px); */
  }
  to {
    opacity: 1;
    /* transform: translateY(0); */
  }
}

@media (max-width: 1210px) {
  .InternetAccordion__panelTitles,
  .InternetAccordion__panelTariffs {
    justify-content: flex-start;
  }

  .InternetAccordion__panelTitle,
  .InternetAccordion__panelTariff {
    /* flex: 0 0 16.66% !important;
    max-width: 16.66% !important; */
    white-space: break-spaces;
  }

  .InternetAccordion__panelTitlesWr,
  .InternetAccordion__panelTariffsWr {
    padding: 0 35px;
  }
}

@media (max-width: 768px) {
  .accordion__button {
    padding: 22px 30px 26px;
  }

  .InternetAccordion__panelTitlesWr,
  .InternetAccordion__panelTariffsWr {
    padding: 0 15px;
  }

  .InternetAccordion__panelTitle,
  .InternetAccordion__panelTariff {
    font-size: 11px;
    line-height: 14px;
    padding: 0 0 0 10px;
  }

  .InternetAccordion__panelTitles,
  .InternetAccordion__panelTariffs {
    margin-left: -10px;
  }

  .accordion__button:before {
    right: 20px;
  }

  .accordion__button {
    padding-right: 45px;
  }
}

@media (max-width: 767px) {
  /* .InternetAccordion__panelTariffs,
  .InternetAccordion__panelTitles {
    display: block;
    overflow-y: auto;
    width: 100%;
    white-space: nowrap;
    margin-left: 0;
  } */

  /* .InternetAccordion__panelTitle,
  .InternetAccordion__panelTariff {
    white-space: break-spaces;
    display: inline-block;
    vertical-align: top;
    margin: 0 10px;
    width: 100%;
    max-width: 30% !important;
  } */

  .InternetAccordion__panelTitle,
  .InternetAccordion__panelTariff {
    font-size: 12px;
    line-height: 14px;
  }
}

@media (max-width: 480px) {
  .InternetAccordion__panelTitle,
  .InternetAccordion__panelTariff {
    max-width: 35% !important;
  }
}
