.bg {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}
.wrap {
  position: relative;
}
.input {
  margin-top: 10px;
  background: #f9f9f9;
  opacity: 1;
  border-radius: var(--input-radius);
  width: 100%;
  border: none;
  font-size: 20px;
  padding: 20px 30px;
  font-weight: 400;
}
.input::placeholder {
  color: rgb(196, 196, 196);
}
.picker {
  position: absolute;
  z-index: 2;
  bottom: 0;
  transform: translateY(calc(110% + 10px));
  padding: 15px;
  background-color: white;
  border-radius: var(--radius);
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.05);
  /* transition: all 0.3s ease; */
}
.picker button {
  font-size: 14px;
  width: 100%;
}
.typeBtn {
  background: #f2f2f2;
  border-radius: var(--radius);
  padding: 15px;
  text-align: left;
  margin-bottom: 3px;
  width: auto !important;
  color: black;
}
.selectedTypeBtn {
  background-color: #f35459;
  color: white;
}
.triangle {
  position: absolute;
  top: -15px;
  left: 20px;
  border-bottom: 20px solid white;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
}
.arrow {
  border-radius: 50%;
  width: 22px;
  height: 22px;
  background-color: #d9d9d9;
  text-align: center;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.2);
  font-weight: 800;
  font-size: 12px;
  line-height: 22px;
}
.p {
  font-weight: 300;
  font-size: 16px;
}
.table {
  margin-top: 10px;
  border-collapse: separate;
  border-spacing: 1px;
}
.table tbody::before {
  content: "@";
  display: block;
  line-height: 5px;
  text-indent: -99999px;
}
.table th {
  font-size: 10px;
  color: rgba(173, 173, 173, 1);
}
.table td {
  font-size: 10px;
  padding: 17px;
  position: relative;
  cursor: pointer;
  transform: translateZ(0);
}
.table td:hover::after {
  content: " ";
  position: absolute;
  width: 94%;
  height: 94%;
  z-index: 1;
  transform: translate(-50%, -50%);
  border: 2px solid var(--primary-color);
  border-radius: 4px;
}
.tableSpan {
  position: absolute;
  top: 50%;
  left: 0;
  text-align: center;
  width: 100%;
  transform: translateY(-50%);
}
.unHighlight {
  color: rgba(173, 173, 173, 1);
  cursor: default !important;
}
.unHighlight::after {
  border: none !important;
}
.hightlight {
  color: white;
  background-color: var(--primary-color);
  border-radius: 4px;
}
