.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 50px 47px 50px;
}
.modal2 {
  width: 356px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
.img {
  width: 142px;
  height: 142px;
  object-fit: contain;
}
.img2 {
  width: 180px;
  height: 48px;
  object-fit: contain;
  margin: 20px 0;
}
.infoItem {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 auto 20px;
  align-items: center;
  gap: 20px;
}
.type {
  color: #757575;
  font-size: 20px;
  font-weight: 400;
  font-family: 'Rubik';
}
.value {
  color: #000;
  font-size: 20px;
  font-weight: 400;
  font-family: 'Rubik';
}
.valueGreen {
  color: #1cc700;
  font-size: 20px;
  font-weight: 400;
  font-family: 'Rubik';
}
.scan_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
}
.scan {
  font-size: 18px;
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  font-weight: 500;
}
.scan > span {
  text-align: center;
}

.pay_link_btn {
  text-align: center;
  background: #f35459;
  color: white;
  border-radius: 6px;
  padding: 10px 15px;
}
.pay_link_btn:hover {
  color: white;
}
.qr {
  margin-top: 10px;
}
.paynet {
  font-size: 20px;
  color: #000;
  text-align: center;
  width: 283px;
  margin-top: 20px;
}
.btn {
  cursor: pointer;
  width: 283px;
  height: 45px;
  border-radius: 7px;
  border: 2px solid #f35459;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-top: 30px;
}
