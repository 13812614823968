.headerMain {
  background-color: var(--white);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  width: 100%;
  padding: 15px 0;
  --iconWidth: 44px;
  .container {
    width: 100%;
    max-width: 1200px;
    padding: 0;
    margin: 0 auto;
  }
  &__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
  }
  .leftSide {
    display: flex;
    align-items: center;
    gap: 50px;
    &__logo {
      img {
        height: 24px;
      }
    }
    &__number {
      display: none;
      color: #000;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px; /* 216.667% */
      border-left: 1px solid #ebebeb;
      padding-left: 12px;
      transition: 0.3s ease;
      &:hover {
        color: #ef233c;
      }
    }
  }
  .rightSide {
    display: flex;
    align-items: center;
    .icon {
      background: #f6f6f6;
      border-radius: 50%;
      min-width: var(--iconWidth);
      min-height: var(--iconWidth);
      width: var(--iconWidth);
      height: var(--iconWidth);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__phone {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-right: 20px;
      padding-right: 20px;
      border-right: 1px solid #ebebeb;
      cursor: pointer;
      &_wrapper {
        display: flex;
        flex-direction: column;
        gap: 2px;
      }
      &_number {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px; /* 130% */
        transition: 0.3s ease;
      }
      &:hover {
        .rightSide__phone_number {
          color: #ef233c;
        }
      }
      &_text {
        color: #ef233c;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 216.667% */
      }
    }
    .massage {
      margin-right: 20px;
      .icon {
        cursor: pointer;
      }
    }
    .profile {
      display: flex;
      align-items: center;
      gap: 20px;
      cursor: pointer;
      &__wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 3px;
      }
      &__name {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        max-width: 200px;
      }
      &__link {
        color: #757575;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 140.625%; /* 16.875px */
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: 0.3s ease;
        gap: 3px;
        .g {
          display: flex;
          align-items: center;
          img {
            transform: rotate(-90deg);
            width: 8px;
          }
        }
      }

      &:hover {
        .profile__link {
          color: #ef233c;
        }
      }
    }
    .oldCabinet{
      background-color: #F6F6F6;
      border-radius: 100px;
      padding:13px 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      .oldCabinetText{
        color: #ef233c;
        font-size: 10px;
        font-weight: 700;
      }
    }
  }
  .lang {
    position: relative;
    &__head {
      width: 100%;
      width: 74px;
      height: 34px;
      border-radius: 10px;
      transition: 0.3s ease;
      background: #ebebeb;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      color: #757575;
      font-size: 16px;
      font-style: normal;
      cursor: pointer;
      font-weight: 400;
      line-height: 140.625%; /* 16.875px */
      img {
        width: 8.5px;
        height: 8.5px;
      }
    }
    &__content {
      position: absolute;
      top: 100%;
      left: 0;
      background: #ebebeb;
      border-radius: 0 0 12px 12px;
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: hidden;
      max-height: 0;
      opacity: 0;
      transition: 0.3s ease;
      will-change: max-height, opacity;
      z-index: 1001;
      &_item {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140.625%; /* 16.875px */
        transition: 0.3s ease;
        color: #757575;
        &:hover {
          color: #ef233c;
        }
      }
    }
    &.open {
      .lang__head {
        border-radius: 12px 12px 0 0;
      }
      .lang__content {
        max-height: 70px;
        opacity: 1;
        &_item {
          padding: 5px 0;
        }
      }
    }
  }
}
.header-phone {
  white-space: nowrap;
}
.header {
  background-color: var(--white);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  .left-side {
    display: flex;
    align-items: center;
    margin-right: 30px;
    gap: 30px;
  }
}
.phone-img {
  width: 42px;
  height: 42px;
  background-color: var(--secondary-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  & > .primary {
    cursor: pointer;
    box-sizing: border-box;
    padding: 10px;
    width: 50px;
    min-width: 50px;
    min-height: 50px;
    background-color: var(--primary-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    & > img {
      height: 30px;
    }
  }
}
#logo {
  height: 24px;
  cursor: pointer;
}
.languages {
  gap: 5px;
  align-items: center;
  display: flex;
}
.languages-mobile {
  display: none;
  background: #f1f1f1;
  border-radius: 100px;
  width: 50px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  position: relative;
  & > .content {
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
    display: none;
    position: absolute;
    width: 50px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 100px;
    width: 100%;
    text-align: center;
    background: white;
    top: 100%;
    left: 0;
    cursor: pointer;
  }
  &:hover {
    & > .content {
      display: block;
    }
  }
}

@media screen and (max-width: 1225px) {
  .profile > .col {
    width: min-content;
  }
  .header-phone {
    font-size: 14px;
  }
}
@media screen and (max-width: 900px) {
  .languages {
    justify-content: space-between;
    width: 100%;
  }
  .profile {
    .primary {
      min-width: 50px;
    }
  }
  .header {
    .left-side {
      width: 100%;
      align-items: center;
      gap: 0px;
    }
  }
  #logo {
    width: 82px;
    height: auto;
  }
}

@media (max-width: 1225.98px) {
  .headerMain {
    .container {
      max-width: 1100px;
    }
  }
}

@media (max-width: 1150.98px) {
  .headerMain {
    .container {
      width: 90%;
    }
  }
}

@media (max-width: 991.98px) {
  .headerMain {
    .leftSide {
      gap: 0px;
      &__number {
        display: block;
        a {
          color: #000;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 26px; /* 216.667% */
        }
      }
      &__logo {
        padding-right: 12px;
        img {
          height: 15px;
        }
      }
      .lang {
        margin-left: 10px;
      }
    }
    .rightSide {
      &__phone {
        display: none;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .headerMain {
    --iconWidth: 35px;
    &__wrapper {
      gap: 10px;
    }
    .icon {
      img {
        height: 15px;
      }
    }

    .rightSide {
      .profile {
        &__wrapper {
          display: none;
        }
      }
      .massage {
        margin-right: 10px;
      }
    }
    .lang {
      &__head {
        width: 50px;
        height: 20px;
        gap: 5px;
        border-radius: 8px;
      }
      &__content {
        &_item {
          font-size: 12px;
        }
      }
      &.open {
        .lang__head {
          border-radius: 8px 8px 0 0;
        }
        .lang__content {
          max-height: 70px;
          border-radius: 0 0 8px 8px;
          &_item {
            padding: 2px 0;
          }
        }
      }
    }
  }

  .headerMain .rightSide .oldCabinet {
    padding: 12px 8px;
    .oldCabinetText{
      font-size: 7px;
    }
  }
}
