.partners-tabs__tab-panel--left {
  flex: 0 0 27%;
  max-width: 27%;
  box-shadow: 0px 4px 25px rgb(0 0 0 / 7%);
  border-radius: 18px;
  padding: 20px;
  height: fit-content;
  .left-image {
    background-color: #F9F9F9;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    overflow: hidden;
  }
  .left-contacts {
    max-width: 100%;
    &__phone {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      font-size: 22px;
      font-weight: bold;
      a {
        width: 100%;
        margin-bottom: 5px;
      }
    }
    &__site {
      margin-top: 10px;
      font-size: 18px;
    }
    &__location {
      font-size: 14px;
      p {
        margin-top: 20px;
      }
    }
  }
}
.partners-tabs__tab-panel--right {
  flex: 0 0 70%;
  max-width: 70%;
  box-shadow: 0px 4px 25px rgb(0 0 0 / 7%);
  border-radius: 18px;
  padding: 20px 20px 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .right-desc {
    margin: 25px 0;
    font-size: 20px;
    line-height: 1.5;
  }
  .right-promo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      color: #E84247;
      font-size: 22px;
      font-weight: bold;
      span {
        color: #E84247;
        font-size: 26px;
      }
    }
    button {
      border: 2px solid #E5474C;
      box-sizing: border-box;
      border-radius: 100px;
      background-color: transparent;
      padding: 10px 50px;
      font-size: 14px;
      transition: .3s all;
      min-width: 238px;
      margin-left: 10px;
      &:hover {
        color: #ffffff;
        background-color: #E5474C;
        transition: .3s all;
        cursor: pointer;
      }
    }
  }
  .right-accordion {
    justify-self: flex-end;
    margin: 20px 0;
    &__item {
      &-heading {
        padding: 35px 50px;
        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
        border-radius: 18px;
        &--button {
          display: flex;
          position: relative;
          line-height: 40px;
          font-size: 15px;
          &::after {
            cursor: pointer;
            content: '+';
            position: absolute;
            color: #E5474C;
            font-size: 40px;
            right: 0;
            top: 0;
          }
        }
        &--button[aria-expanded="true"] {
          &::after {
            content: '-';
          }
        }
      }
      &-panel {
        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
        border-radius: 18px;
        padding: 35px 50px;
        margin-top: 13px;
        transition: 0.2s all;
        .promo-item {
          display: flex;
          margin-bottom: 15px;
          img {
            height: 16px;
            margin-right: 20px;
          }
        }
        .promo-help {
          margin-top: 30px;
          color: rgba(0, 0, 0, 0.3);
          font-size: 14px;
        }
      }
    }
  }
}

@media(max-width: 1200px) {
  .partners-tabs__tab-panel--left {
    .left-contacts {
      &__phone {
        font-size: 18px;
      }
      &__site {
        font-size: 16px;
      }
      &__location {
        font-size: 14px;
      }
    }
  }
  .partners-tabs__tab-panel--right {
    .right-desc {
      font-size: 16px;
      line-height: 1.4;
    }
    .right-promo {
      display: flex;
      justify-content: space-between;
      div {
        color: #E84247;
        font-size: 22px;
        font-weight: bold;
        span {
          color: #E84247;
          font-size: 26px;
        }
      }
      button {
        border: 2px solid #E5474C;
        box-sizing: border-box;
        border-radius: 100px;
        background-color: transparent;
        padding: 10px 50px;
        font-size: 14px;
        transition: .3s all;
        &:hover {
          color: #ffffff;
          background-color: #E5474C;
          transition: .3s all;
        }
      }
    }
    .right-accordion {
      margin-top: 30px;
      &__item {
          cursor: pointer!important;
          &-heading {
          padding: 35px 50px;
          box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
          border-radius: 18px;
          &--button {
            display: flex;
            position: relative;
            line-height: 40px;
            font-size: 15px;
            &::after {
              content: '+';
              position: absolute;
              color: #E5474C;
              font-size: 40px;
              right: 0;
              top: 0;
            }
          }
          &--button[aria-expanded="true"] {
            &::after {
              content: '-';
            }
          }
        }
        &-panel {
          box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
          border-radius: 18px;
          padding: 35px 15px;
          margin-top: 13px;
          transition: 0.2s all;
          .promo-item {
            display: flex;
            margin-bottom: 15px;
            img {
              height: 16px;
              margin-right: 20px;
            }
          }
          .promo-help {
            margin-top: 30px;
            color: rgba(0, 0, 0, 0.3);
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media(max-width: 767px) {
  .partners-tabs__tab-panel {
    flex-wrap: wrap;
    &--container {
      flex-wrap: wrap;
      .partners-tabs__tab-panel--left {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 30px;
        text-align: center;
      }
      .partners-tabs__tab-panel--right {
        flex: 0 0 100%;
        max-width: 100%;  
        .right-title {
          text-align: center;
        }

        .right-promo {
          flex-wrap: wrap;
          div {
            width: 100%;
          }
          button {
            width: 100%;
            margin-top: 15px;
            margin-left: 0px;
          }
        }
      }
    }
  }
}

//FIX 320px a lot of extra code

@media(max-width: 320px) {
  .partners-tabs__tab-panel--left {
    .left-contacts {
      &__phone {
        font-size: 18px;
      }
      &__site {
        font-size: 16px;
      }
      &__location {
        font-size: 14px;
      }
    }
  }
  .partners-tabs__tab-panel--right {
    .right-desc {
      font-size: 18px;
      line-height: 1.4;
    }
    .right-promo {
      display: flex;
      justify-content: space-between;
      div {
        color: #E84247;
        font-size: 22px;
        font-weight: bold;
        span {
          color: #E84247;
          font-size: 26px;
        }
      }
      button {
        border: 2px solid #E5474C;
        box-sizing: border-box;
        border-radius: 100px;
        background-color: transparent;
        padding: 10px 50px;
        font-size: 14px;
        transition: .3s all;
        &:hover {
          color: #ffffff;
          background-color: #E5474C;
          transition: .3s all;
        }
      }
    }
    .right-accordion {
      margin-top: 30px;
      &__item {
          cursor: pointer!important;
          &-heading {
          padding: 20px 16px;
          box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
          border-radius: 18px;

        }
        &-panel {
          box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
          border-radius: 18px;
          padding: 20px 16px;
          margin-top: 13px;
          transition: 0.2s all;
        }
      }
    }
  }
}