.main {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 1em;
}
.th {
  border: none;
  font-size: 16px;
  text-align: left;
  padding: 0 5px;
}
.trHead > :first-child {
  padding: 0 0 0 30px;
}
/* .trHead > :last-child {
  padding: 0 30px 0 0;
} */

.td {
  text-align: left;
  border: none;
  padding: 20px 5px;
  white-space: nowrap;
}
.trItem {
  background: #f2f2f2;
  border-radius: var(--radius);
}
.trItem:nth-child(even) {
  background: rgba(242, 242, 242, 0.5);
}

.trItem > :first-child {
  border-top-left-radius: var(--radius);
  border-bottom-left-radius: var(--radius);
  padding: 20px 20px 20px 30px;
}
.trItem > :last-child {
  border-top-right-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
  padding: 20px 30px 20px 0px;
}

.pagination {
  margin: 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
}
.pg_btn {
  background: #f2f2f2;
  border-radius: var(--radius);
  padding: 12px 15px;
  font-size: 20px;
  color: #696969;
}
.current_pg {
  background-color: var(--primary-color);
  color: white;
}
.arrow {
  background-color: transparent;
  font-size: 20px;
  color: rgba(105, 105, 105, 0.42);
  padding: 0 5px;
}
.wrap {
  width: 100%;
  overflow: auto;
}
.no_data {
  text-align: center;
  font-size: 20px;
  background-color: rgba(242, 242, 242, 0.5);
  border-radius: var(--radius);
  padding: 10px 0;
}

@media screen and (max-width: 1100px) {
  .main {
    border-spacing: 0 10px;
  }
  .th:first-child {
    padding-left: 5px;
    padding-bottom: 10px;
  }
  .th {
    padding: 0 10px;
    padding-bottom: 10px;
    font-size: 16px;
    /* text-align: center; */
  }
  .trItem .td:first-child {
    padding: 10px 0px 10px 20px;
  }
  .trItem .td:last-child {
    padding: 10px 20px 10px 0px;
  }
  .td {
    padding: 15px 15px;
    font-size: 14px;
  }
}
