.bg {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}
.wrap {
  position: relative;
}
.content {
  position: absolute;
  z-index: 2;
  padding: 15px;
  background-color: white;
  border-radius: var(--radius);
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.05);
  right: 0;
  top: 20px;
}
.triangle {
  position: absolute;
  top: -15px;
  right: 20px;
  border-bottom: 20px solid white;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
}

@media screen and (max-width: 850px) {
  .bg {
    background-color: rgba(5, 5, 5, 0.3);
  }
  .content {
    position: fixed;
    top: 0;
    right: auto;
    left: 0;
    width: 100%;
    transform: translateY(calc(100% - 213px));
    border-radius: var(--radius) var(--radius) 0 0;
  }
}
