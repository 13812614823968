.wrap {
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  border-radius: var(--radius);
  padding: 30px 20px;
  display: flex;
  gap: 20px;
  font-size: 20px;
  cursor: pointer;
  width: 218px;
}
.radio {
  border: 6px solid #e0e0e0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  pointer-events: none;
}
.selected {
  border-color: var(--primary-color);
}
.disabled {
  border-color: #e0e0e0;
  background-color: #e0e0e0;
}