.label {
  text-align: start;
}
.errorLabel span {
  color: black;
}
.errorInput {
  background-color: #ffdfdf !important;
}
.input {
  margin-top: 10px;
  background: #f2f2f2;
  opacity: 1;
  border-radius: var(--input-radius);
  width: 100%;
  border: none;
  font-size: 20px;
  padding: 20px 30px;
  font-weight: 400;
}
.input:read-only {
  color: #5a5a5a;
}
.rightSuffix {
  position: absolute;
  right: 20px;
  top: 55%;
  transform: translateY(-50%);
}
.miniInput {
  padding: 12px 20px !important;
  font-size: 16px !important;
  margin-top: 5px !important;
}
.wrap {
  display: flex;
  gap: 1em;
  align-items: flex-end;
  position: relative;
}
