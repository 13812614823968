.service_wrap {
  display: flex;
  justify-content: flex-start;
  gap: 50px;
  margin: 30px 0;
  margin-bottom: 50px;
}

.sidebar {
  /* border: 2px solid red; */
}

.left_side {
  /* width: 50%; */
  width: min-content;
}

.right_side {
  /* width: 100%; */
}

.left_content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 20px;
  background: #f9f9f9;
  padding: 30px 35px 25px 35px;
}

.svg_wrap svg {
  width: 100%;
}

.info_text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 170%;
  color: #898b96;
}

.service_price {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 60px;
  letter-spacing: -0.408px;
  color: rgba(0, 0, 0, 0.8);
  gap: 20px;
  white-space: nowrap;
}

.service_price span {
  font-style: normal;
  font-weight: bold;
  font-size: 65px;
  line-height: 60px;
  letter-spacing: -0.408px;
  color: rgba(0, 0, 0, 0.8);
}

/* RIGHT_SIDE */
.left_content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  /* padding: 45px; */
}

.main_title {
  color: #000;
  font-family: Rubik;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  margin-top: 30px;
}

.about {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px 0 40px 0;
}

.title {
  color: #1e1e1e;
  font-size: 20px;
  line-height: 25px;
}

.descr {
  color: #898b96;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
  font-size: 18px;
}

.connect_btn {
  background: #f35459;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  margin-top: 25px;
}

.payment_warning {
  color: #898b96;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
  font-size: 16px;
  color: #f35459;
}

.number_field {
  background: #eaeaea;
  border-radius: 10px;
  border: none;
  padding: 20px 20px;
  font-size: 18px;
  font-weight: 500;
  color: #898b96;
}

.font_color {
  color: #000 !important;
}

.notice {
  color: #898b96;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  font-size: 16px;
  color: #f35459;
  font-style: italic;
  padding: 10px;
  margin-top: 10px;
}

.term_list {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cover_zone {
  color: #f35459;
  font-size: 18px;
  text-decoration: underline;
  font-style: italic;
}

@media (max-width: 900px) {
  .service_wrap {
    flex-wrap: wrap;
  }

  .left_content {
    padding: 20px;
  }

  .left_side {
    /* width: 50%; */
    width: 100%;
  }

  .svg_wrap > img {
    width: 100%;
  }
}
