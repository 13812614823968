.title {
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 40px;
}
.main_block {
  padding: 40px 30px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
  border-radius: 21px;

  display: flex;
  flex-direction: column;
  gap: 20px;
  transition: box-shadow 0.3s ease-out;
  contain: style;
}
.mini {
  padding: 25px 35px 20px 35px;
  gap: 10px;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  position: relative;
}
.main_block img {
  user-select: none;
}
.checked {
  box-shadow: 0px 5px 40px rgb(0 0 0 / 30%);
}

@media screen and (max-width: 850px) {
  .main_block {
    padding: 25px 25px !important;
    border-radius: 10px;
    justify-content: center;
    display: flex !important;
  }
  .main_block > * {
    width: 100%;
  }
  .mini {
    padding: 20px 20px !important;
  }
}
