.col {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
  height: 100%;
}
.block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.dots {
  display: flex;
  gap: 10px;
}
.dot {
  background-color: #e2e2e2;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.selectedDot {
  background-color: var(--primary-color);
}
.arrow {
  background: #ffffff;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.2);
  font-size: 30px;
  padding: 10px 15px;
}
.arrow:disabled {
  cursor: default;
}
.payment {
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
  border-radius: 18px;
  padding: 0px 35px;
  width: 235px;
  height: 141px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.payment > img {
  width: 100%;
}
.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  transition: 0.3s;
}
