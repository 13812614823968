@font-face {
  font-family: "Magistral";

  src: url("../../assets/fonts/Magistral-BoldItalic.ttf");
}

.comnetTitle {
  font-family: Magistral;
  width: 100%;
  background: #fafafa;
  border-radius: 30px;
  font-weight: bold;
  font-size: 24px;
  color: #000000;
  padding: 25px 0;
  padding-left: 60px;
  // padding-right: 20px;
  // margin-bottom: 40px;
  font-style: italic;
  text-align: center;
  align-items: center;
  display: flex;
}

.comnetTitle img {
  max-width: 120px;
  padding-left: 30px;
}

.comnetTitle > p {
  font-family: Magistral;
  font-size: 24px;
  margin-right: 15px;
}

