$index: calc(1vw + 1vh);
// font-size: calc(max(var($index) * 0.545, 16px));

@mixin font-size($px) {
  font-size: calc(max($index * 0.7, $px));
}
.customDotList {
  position: absolute !important;
  bottom: 65px !important;
  left: 0;
  right: 0;
  width: 100%;
  height: 20px;
}

.customDot {
  width: 10px !important;
  height: 10px !important;
  background: #e2e2e2 !important;
  margin: 0 0.5rem;
  flex: 0 0 10px;
  min-width: 10px !important;
}
.customDotActive {
  background: #e6474c !important;
}

.TopSlider__carousel button:not(.TopSlider__btn) {
  z-index: 6;
  background: white;
  /* box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.07); */
  box-shadow: none;
  border: none;
  border-radius: 10px;
  width: 50px;
  height: 56px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.2);
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
  width: 52px;
  height: 60px;
}
.TopSlider__carousel {
  margin: 0 -2rem;
}
.TopSlider__carousel button:not(.TopSlider__btn):hover:not(:disabled) {
  background: #e6474c;
  color: #ffffff;
}

.TopSlider__carousel button:not(.TopSlider__btn):before {
  color: inherit;
}

.TopSlider__carousel button[aria-label="Go to previous slide"] {
  display: none;
  left: 6px;
}

.TopSlider__carousel button[aria-label="Go to next slide"] {
  display: none;
  right: 6px;
}

.TopSlider__carousel button[aria-label="Go to previous slide"]:before {
  padding-right: 2px;
}

.TopSlider__carousel button[aria-label="Go to next slide"]:before {
  padding-left: 2px;
}

.TopSlider__btn {
  padding: 20px 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #000;
  background: transparent;
  border-radius: 10px;
  border: 2px solid #e6474c;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.TopSlider__btn:hover {
  background: #e6474c;
  color: #ffffff;
}

.TopSlider__left {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 4rem;
}

.small__title {
  min-height: 30px;
  width: 80%;
  margin: 0 auto;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1rem;
}
.TopSlider__item {
  display: flex;
  flex-direction: column-reverse;
  padding: 0 2rem;
}
.imgBanner {
  height: 200px;
  object-fit: contain;
}

.TopSlider__title {
  font-weight: 700;
  display: none;
}

.TopSlider__text {
  font-size: 14px;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 991px) {
  .customDotList {
    display: none !important;
  }
  .small__title {
    display: none;
  }
  .TopSlider__title {
    display: block;
  }
  .imgBanner {
    height: auto;
    object-fit: contain;
  }

  .TopSlider__item {
    .TopSlider__right {
      flex: 0 0 50%;
      width: 50%;
    }
  }
  .TopSlider__carousel button[aria-label="Go to previous slide"] {
    display: block;
  }

  .TopSlider__carousel button[aria-label="Go to next slide"] {
    display: block;
  }

  .TopSlider__item {
    display: flex;
    flex-direction: row;
    .TopSlider__left {
      height: auto;
      flex: 0 0 40%;
      width: 40%;
      padding: 0 4.5rem;
      justify-content: center;
      gap: 2rem;
    }
    .TopSlider__title {
      @include font-size(38px);
    }
    .TopSlider__text {
      height: auto;
      @include font-size(18px);

      text-align: left;
    }
    .TopSlider__right {
      flex: 0 0 60%;
      width: 60%;
      padding: 0 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .TopSlider__title {
    font-size: 40px;
    font-weight: 700;
  }
  .TopSlider__btn {
    width: max-content;
    border-radius: 100px;
    padding: 16px 60px;
  }
  .TopSlider__carousel div {
    position: relative;
    min-height: auto;
    height: 100%;
  }
}
