.partners {
  .partners__title {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    h1 {
      font-size: 40px;
      margin-bottom: 30px;
    }
    &-info {
      font-size: 20px;
      line-height: 1.7;
      span {
        font-weight: bold;
        color: #e84247;
      }
    }
  }
}

@media (max-width: 767px) {
  .partners {
    .partners__title {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      h1 {
        font-size: 32px;
        margin-bottom: 20px;
        // text-align: center;
      }
      p {
        font-size: 22px;
        text-align: center;
      }
    }
  }
}
