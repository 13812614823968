.container {
  margin-top: 30px;
  width: 386px;
}
.container img {
  margin-bottom: 30px;
}
.backImage {
  margin-bottom: 30px;
  width: 386px;
  height: 204px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 7px;
  overflow: hidden;
  position: relative;
}
.backImage img {
  position: absolute;
  top: 10px;
  right: 10px;
}
.title {
  font-size: 22px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: bold;
}
.info {
  font-size: 20px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 20px;
}
.bonus {
  background-color: #e5474c;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 7px 14px;
  width: fit-content;
  border-radius: 50px;
  margin: 0 auto;
}
.bonus2 {
  background-color: #e5474c;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 7px 14px;
  width: fit-content;
  border-radius: 50px;
  margin-left: 15px;
}
.prizeList {
  display: flex;
  align-items: center;
}
.prizeList img {
  margin: 0;
  height: auto !important;
}
.bonus p,
.bonus2 p {
  color: #fff;
  font-size: 18px;
}
