.reset-page {
  height: 100%;
}
.reset-note {
  & > :first-child {
    padding: 20px !important;
    top: 10%;
    width: 80%;
    max-width: 900px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: red;
  }
}
.reset-body {
  & > h1 {
    margin-bottom: 10px;
  }
  max-width: 40%;
  text-align: center;
  margin: 0 auto;
  margin-top: 80px;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 20px;
}
.reset-button-wrap {
  width: 100%;
}
@media screen and (max-width: 850px) {
  .reset-body {
    max-width: fit-content;
    margin-top: 20px !important;
    margin-bottom: 30px;
    // & > :first-child {
    //   position: static;
    //   transform: translate(0);
    //   margin: 0 auto;
    // }
  }
}
@media screen and (max-width: 1170px) {
  .reset-note {
    & > :first-child {
      font-size: 12px;
      top: 5%;
      position: static;
      transform: translate(0);
      margin: 0 auto;
    }
  }
  .reset-body {
    max-width: fit-content;
    margin-top: 100px;
    margin-bottom: 80px;
  }
}
