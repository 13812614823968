.accordion {
  &__item {
    cursor: pointer;
    align-items: center;
    padding: 22px 34px;
    transition: 0.2s all;
    margin-bottom: 10px;
    .accordion__heading {
      width: 100%;
      &-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .content {
          &__title {
            margin-bottom: 7px;
            font-size: 20px;
            transition: 0.1s all;
          }

          &__subtitle {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.3);
          }
        }
        .expand {
          color: #e5474c;
          font-size: 40px;
        }
      }

      &-button[aria-expanded='true'] {
        .content {
          &__title {
            color: #e5474c;
            transition: 0.1s all;
          }
        }
      }
    }
    margin-bottom: 10px;
    .accordion__heading--notes {
      width: 100%;
      &-button {
        display: flex;
        span {
          font-size: 30px;
          color: #e5474c;
          margin-right: 12px;
          margin-top: -4px;
        }
        .content {
          &__title {
            margin-bottom: 7px;
            font-size: 15px;
          }
        }
      }
    }
    .accordion__panel {
      &-text {
        white-space: normal;
        font-weight: 300;
        font-size: 16px;
      }
    }
  }
}
