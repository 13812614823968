.News {
  width: 100%;
}
.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;

  background: #ffffff;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  width: 52px;
  height: 56px;

  border: none;

  display: flex;
  justify-content: center;
  align-items: center;
}
.News__inner {
  max-width: 1200px;
  margin: 0 auto 50px;
  text-align: left;
}
.item {
  width: 300px;
}
.carusel {
  box-shadow: none !important;
}
.News__top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 30px;
}

.News__mainlinkText {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 40px;
  color: #000000;
}

.News__mainlink {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);
}

.News__mainlink:hover {
  color: #e5474c;
  text-decoration: underline;
}

/* .News__mainlink img {
	margin-left: 10px;
} */
/* 
.News__items {
	display: flex;
	flex-wrap: wrap;
	margin-left: -7px;
} */

.News__col {
  /* flex: 0 0 25%;
	max-width: 25%;
	padding: 0 0 7px 7px; */
  height: 100%;
  padding: 0 10px;
}

.News__item {
  background: #ffffff;
  border: 1px solid #ececf3;
  box-sizing: border-box;
  border-radius: 8px;
  height: 100%;
  user-select: none;
}

.News__pic img {
  border-radius: 8px 8px 0 0;
  width: 100%;
}

.News__date {
  display: flex;
  justify-content: space-between;
  padding: 5px 15px 0 15px;
  align-items: center;
}

.News__date .News__news {
  background-color: rgba(255, 153, 0, 0.12);
  border-radius: 100px;
  color: #ff9900;
  padding: 10px;
}
.News__date .News__promo {
  background-color: rgba(255, 172, 146, 0.12);
  border-radius: 100px;
  color: #ff3939;
  padding: 10px;
}
.News__date .News__article {
  background-color: rgba(0, 148, 255, 0.12);
  border-radius: 100px;
  color: #00c2ff;
  padding: 10px;
}

.News__info {
  padding: 15px 20px 10px;
}

.News__infoTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
  font-size: 15px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.6);
}

.News__tag {
  padding: 5px;
  min-width: 55px;
  min-height: 20px;
  background: rgba(255, 153, 0, 0.12);
  backdrop-filter: blur(4px);
  border-radius: 100px;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 10px;
  color: #ff9900;
  text-align: center;
}

.News__date {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15%;
  text-align: right;
  color: #b2b5c2;
}

.News__title {
  display: block;
  min-height: 38px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #2b2c32;
}

.News__title:hover {
  text-decoration: underline;
  color: #e6474c;
}
.News__slider__items {
  width: 300px;
  height: 232px;
}
.News__slider {
  padding-bottom: 30px;
  width: 100%;
  overflow: visible !important;
}
.News__slider > :first-child {
  overflow: visible !important;
}
.react-multi-carousel-track {
  width: 90vw;
}
.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
  left: 0;
}

.News__slider div {
  position: relative;
  min-height: auto;
}

.News__slider div:focus {
  outline: none;
}

.News__slider button {
  position: absolute;
  z-index: 6;
  background: #ffffff;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  width: 50px;
  height: 56px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.2);
}

.News__slider button:hover:not(:disabled),
.News__slider button:focus:not(:disabled) {
  background: #e6474c;
  color: #ffffff;
}

.News__slider button:before {
  color: inherit;
}

.News__slider button[aria-label="Go to previous slide"] {
  left: 0;
}

.News__slider button[aria-label="Go to previous slide"]:before {
  padding-right: 2px;
}

.News__slider button[aria-label="Go to next slide"] {
  right: 0;
}

.News__slider button[aria-label="Go to next slide"]:before {
  padding-left: 2px;
}

.News__dots {
  bottom: 0;
}

.News__dots li {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 6px;
}

.News__dots li button {
  background: #e2e2e2;
  border: none;
  box-shadow: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.News__dots li[class*="--active"] button {
  background: #e5474c;
}

@media (max-width: 1200px) {
  .News__col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .News__mainlinkText {
    font-size: 30px;
    line-height: 30px;
  }

  .react-multi-carousel-item {
    width: 100%;
  }
}
