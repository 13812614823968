.main_banner_wrapper {
  background: #fafafa;
  padding: 20px 20px 30px 20px;
  width: 100%;
}

.main_banner {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 15px 0px;
  height: 100%;
  display: grid;
  grid-template-columns: 50% minmax(100px, 100%);
}

.right_side {
  display: flex;
  align-items: flex-end;
}

.right_side > img {
  width: 100%;
}

.left_side {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.left_side > h2 {
  font-size: 40px;
}

.left_side > p {
  font-size: 18px;
  line-height: 25px;
}

.anchor_btns {
  display: flex;
  gap: 20px;
}

.anchor_btns > button {
  padding: 15px 20px;
  border: 1px solid #e5474c;
  background: white;
  border-radius: 50px;
  color: #e5474c;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
}

.anchor_btns > button:last-child {
  background: #e5474c;
  color: #ffffff;
}

@media screen and (max-width: 930px) {
  .main_banner_wrapper {
    background: #fafafa;
    padding: 20px 20px 30px 40px;
  }

  .right_side > img {
    width: auto;
  }
}

@media screen and (max-width: 740px) {
  .main_banner_wrapper {
    background: #fafafa;
    padding: 20px 20px 30px 20px;
  }

  .main_banner {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 15px 0px;
    height: 100%;
  }

  .left_side {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .left_side > h2 {
    font-size: clamp(28px, 5vw, 33px);
    text-align: center;
  }

  .left_side > p {
    font-size: 18px;
    line-height: 25px;
  }

  .left_side > img {
    width: 100%;
  }
}

@media screen and (max-width: 440px) {
  .anchor_btns {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }

  .anchor_btns > button {
    color: #e5474c;
    font-weight: 500;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
  }
}
