.row {
  display: flex;
  gap: 10px;
  margin-bottom: 35px;
}
.button {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  border-radius: var(--radius);
  padding: 25px;
  font-size: 20px;
  color: black;
}
.selected {
  color: var(--primary-color);
}
.wrapChildren {
  padding: 0 5px;
}
