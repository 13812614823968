.wrap {
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  border-radius: var(--radius);
  padding: 25px 20px 30px 30px;

  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 290px;
  height: 250px;
}
.wrap span {
  font-size: 13px;
}
.download {
  height: 22px;
}
.button {
  display: flex;
  gap: 5px;
  cursor: pointer;
}
.title {
  font-size: 20px;
  line-height: 19px;
}
